/**
* Baker Distributing
*/


// ==============================================
// Dropdown
// ==============================================

.dropdown {
    position: relative;
    display: inline-block;
}


// ==============================================
// Dropdown Trigger
// ==============================================


.dropdown-trigger {
    cursor: pointer;
    user-select: none;

    @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 22px,
            $_icon-font-position: after,
            $_icon-font-display: block
    );
}

.dropdown-trigger:hover {
    text-decoration: none;
}

.dropdown-trigger.active {
    @include lib-icon-font(
            $_icon-font-content: $icon-up,
            $_icon-font-size: 22px,
            $_icon-font-position: after,
            $_icon-font-display: block
    );
}


// ==============================================
// Dropdown Content
// ==============================================


.dropdown-content {
    position: absolute;
    background: $c-white;
    border: 1px solid $c-border;
    box-shadow: $box-shadow-base;
    margin-top: 4px;
    height: auto;
    width: 260px;
    left: 0px;
    z-index: $z-index-9;
}

.dropdown .dropdown-content {
    display: none;
}

.dropdown.active .dropdown-content {
    display: block;
}

// ==============================================
// Dropdown Content - size modifiers
// ==============================================

.dropdown-content--m {
    width: 320px;
}

.dropdown-content--l {
    width: 660px;
}

.dropdown-content--right {
    left: auto;
    right: -20px;
}


// ==============================================
// Dropdown Content list
// ==============================================


ul.dropdown-content li {
    margin: 0;
}

ul.dropdown-content li:hover {
    background: $c-primary;
}

ul.dropdown-content li a {
    display: block;
    color: $c-black;
    padding: 8px 16px;
}

ul.dropdown-content li:hover a {

    color: $c-white;
    text-decoration: none;
}


// ==============================================
// Dropdown Footer
// ==============================================

.dropdown-footer {
    border-top: 1px solid $c-border;
    padding: 8px;
    margin: 16px -16px -16px -16px;
}