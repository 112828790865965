/**
* Baker Distributing
*/


// ==============================================
// Product Image Photo
// ==============================================

.product-image-photo {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    height: auto;
    margin: auto;
}
