/**
* Baker Distributing
*/


// ==============================================
// Field
// ==============================================

.field {
    margin: 0 0 32px 0;
}

.field.required label {
    &::after {
        content: '*';
        margin: 0 0 0 5px;
    }
}

.field.required .input-text {
    margin-right: 16px;
}

// ==============================================
// Modifier inline
// ==============================================

.field--inline {
    display: flex;
}


// ==============================================
// Field . Password
// ==============================================

.field.password .control {
    display: flex;
    flex-direction: column;
}

.field.password .control .mage-error {
    order: 2;
}

.field.password .control .input-text {
    order: 0;
    z-index: 2;
}
