/**
* Baker Distributing
*/


// ==============================================
// Individual Loading
// ==============================================

.individual-loading {
    display: inline;
    padding: $space-xxxs $space-xxs;
    background: linear-gradient(300deg, $c-lightestgray, $c-lightgray, $c-lightestgray);
    background-size: 1200%;
    color: $c-gray;
    font-size: $font-size-xs;
    font-weight: 300;
    letter-spacing: 0.03rem;
    text-rendering: optimizeLegibility;
    text-transform: lowercase;
    animation: gradientLoading 3s ease infinite;
}
