/**
* Baker Distributing
*/


// ==============================================
// Set Location
// ==============================================
.set-location {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.set-location_current,
.set-location_branch {
    height: auto;
    width: 100%;
    flex: 0 1 100%;
    margin-bottom: $space-xs;
    background: $c-white;
    display: inline-block;
    text-transform: initial;
    font-size: $font-size-xs;
    font-weight: 400;
    letter-spacing: $letter-spacing;
    color: $c-link;
    vertical-align: middle;
    text-align: center;
    padding: $space-xxs;
    border-radius: $radius-base;
    border: 1px solid $c-border;
    cursor: pointer;
    transition: $transition-background;
}

@media (max-width: $bp-screen-m - 1px) and (min-width: $bp-screen-s) {
    .set-location_current,
    .set-location_branch {
        width: 49%;
        flex-basis: 49%;
    }
}

.set-location_current:hover,
.set-location_branch:hover {
    background: $c-lightestgray;
}


.set-location_current,
.my-location {
    @include lib-icon-font(
                    $icon-mylocation,
            $_icon-font-size: 18px,
            $_icon-font-position: before,
            $_icon-font-margin: 0 6px 0 0
    );

    &:before {
        width: 20px;
        height: 20px;
    }
}

.set-location_branch {
    @include lib-icon-font(
                    $icon-store,
            $_icon-font-size: 18px,
            $_icon-font-position: before,
            $_icon-font-margin: 0 6px 0 0
    );

    &:before {
       width: 20px;
        height: 20px;
    }
}

.set-location_loading::before {
    background-image: $icon-loading--blue;
    color: transparent;
    margin: 2px 8px 2px 2px;
    width: 16px;
    height: 16px;
    position: static;
    animation: rotate-clockwise 4s linear infinite;
}
