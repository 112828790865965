/**
* Baker Distributing
*/


// **********************************************
// GravDept: [todo]
// Refactor.
// Determine if this is used.
// **********************************************


// ==============================================
// Block Banners
// ==============================================

.block-banners,
.block-banners-inline {
    margin-bottom: $space-xxl;
}

/*
.block-banners        .banner-items,
.block-banners-inline .banner-items {}
*/

.block-banners        .banner-item,
.block-banners-inline .banner-item {
    display: block;
}

.block-banners        .banner-item-content,
.block-banners-inline .banner-item-content {
    margin-bottom: $space-l;
}

.block-banners        img,
.block-banners-inline img {
    display: block;
    margin: 0 auto;
}
