/**
* Baker Distributing
*/

// ==============================================
// Vars
// ==============================================

$button-box-shadow:         $box-shadow-base !default;
$button-box-shadow-hover:   $box-shadow-hover !default;

@mixin button  {
    //background-image: none;
    @include button-base;

    @include button-style(
            $background         : $blue-800,
            $background--hover  : $blue-900,
            $background--active : $blue-900,
            $color              : $c-white
    );

    color: $c-white;
    box-shadow: $button-box-shadow;
    white-space: nowrap;

    &:hover, &:focus {
        background: $blue-900;
        color: $c-white;
        box-shadow: $button-box-shadow-hover;
    }
}

@mixin flat-button {
    @include button-base;

    @include button-style(
        $background         : transparent,
        $background--hover  : $blue-50,
        $background--active : $blue-100,
        $color              : $blue-800
    );
}

@mixin button-style (
    $background         : $blue-700,
    $background--hover  : $blue-800,
    $background--active : $blue-800,
    $color              : $c-white
) {
    background: $background;
    color: $color;

    &:hover, &:focus {
        color: $color;
        background:  $background--hover;
    }

    &:active {
        color: $color;
        background:  $background--active;
    }
}

@mixin button-base {
    height: 38px;
    border: 0;
    border-radius: $radius-base;
    cursor: pointer;
    display: inline-block;
    font-weight: $font-weight-normal;
    padding: 8px 16px;
    font-size: 14px;
    letter-spacing: $letter-spacing;
    vertical-align: middle;
    text-align: center;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    will-change: background, box-shadow;
    transition: $transition-all;

    &:hover, &:focus {
        text-decoration: none;
    }
    &:disabled {
        transition: none;
    }
}
