/**
* Baker Distributing
*/


// **********************************************
// This file was forked from the GravDept Frontend Toolkit.
// See: /core/reset/_gravdept-typography.scss
// **********************************************


// ==============================================
// Default Typography
// ==============================================

body,
button,
input,
table,
textarea {
    font-family: $font-family-sans;
    color: $c-text;
    font-size: $font-size-base;
    line-height: $line-height-base;
}

// ==============================================
// Anchor
// ==============================================

a {
    color: $c-link;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $c-link-hover;
        text-decoration: underline;
    }

    &:active {
        color: $c-link-active;
    }

    &:focus {
        outline: 0;
        color: $c-link-focus;
    }
}

a.disabled {
    color: $c-text-muted;

    &:hover,
    &:active,
    &:focus {
        color: $c-text-muted;
        text-decoration: none;
    }
}

a.dark {
    color: $c-link-dark;

    &:hover {
        color: $c-link-dark-hover;
    }

    &:active,
    &:focus {
        color: $c-link-dark-active;
    }
}

a.link-secondary {
    color: $c-text;
}

// ==============================================
// Strong
// ==============================================

strong {
    @include bold;
}

// ==============================================
// Blockquote
// ==============================================

blockquote {
    margin-bottom: $gap;
    font-size: $font-size-xxxl;
    font-weight: 300;
    font-style: italic;
    line-height: $line-height-xs;

    &::before {
        content: '\201C';
    }

    &::after {
        content: '\201D';
    }
}


// ==============================================
// Heading
// ==============================================

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: inherit;
    font-weight: normal;
    font-style: normal;
}

h1, .h1 { @include h1; }
h2, .h2 { @include h2; }
h3, .h3 { @include h3; }
h4, .h4 { @include h4; }
h5, .h5 { @include h5; }
h6, .h6 { @include h6; }

// ----------------------------------------------

@media print {

    h1, .h1 { font-size: 24px !important; }
    h2, .h2 { font-size: 18px !important; }
    h3, .h3 { font-size: 14px !important; }
    h4, .h4 { font-size: 12px !important; }

}


// ==============================================
// List
// ==============================================

ol,
ul {
    list-style: none;
}


// ==============================================
// Paragraph
// ==============================================

p {
    margin-bottom: $margin-bottom;
    line-height: $line-height-base;
}


// ==============================================
// Small
// ==============================================

small,
.small {
    font-size: $font-size-xs;
}


// ==============================================
// Label
// ==============================================

.label {
    @include label;
}
