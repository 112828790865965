/**
* Baker Distributing
*/


// ==============================================
// Store Hour
// ==============================================


// ==============================================
// Store Hour - Detail
// ==============================================

.storeHour-detail {
    padding: 10px;
}

.storeHour-detail td {
    padding: $space-xxxs 0;

    &:first-child {
        padding-right: $space-base;
    }
}
