/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Mixin - Typography
// ==============================================

@mixin bold {
    font-weight: 500;
    letter-spacing: $letter-spacing;
}

@mixin caption {
    color: $c-text;
    font-family: $font-family-sans;
    font-size: $font-size-s;
    font-style: normal;
    font-weight: normal;
}

@mixin h1 {
    margin-bottom: 0.6em;
    color: $c-h1;
    font-family: $font-family-sans;
    font-size: $font-size-xxxl;
    font-weight: 300;
    font-style: normal;
    line-height: $line-height-xxs;
    text-transform: none;
}

@mixin h2 {
    margin-bottom: 0.75em;
    color: $c-h2;
    font-family: $font-family-sans;
    font-size: $font-size-xxl;
    font-weight: 400;
    font-style: normal;
    line-height: $line-height-xxs;
    text-transform: none;
}

@mixin h3 {
    margin-bottom: 10px;
    color: $c-h3;
    font-family: $font-family-sans;
    font-size: $font-size-xl;
    font-weight: 400;
    font-style: normal;
    line-height: $line-height-s;
    text-transform: none;
}

@mixin h4 {
    margin-bottom: 10px;
    color: $c-h4;
    font-family: $font-family-sans;
    font-size: $font-size-base;
    font-style: normal;
    font-weight: 400;
    line-height: $line-height-s;
    text-transform: none;
}

@mixin h5 {
    margin-bottom: 10px;
    color: $c-h5;
    font-family: $font-family-sans;
    font-size: $font-size-s;
    font-style: normal;
    line-height: $line-height-s;
    text-transform: uppercase;
    @include bold;
}

@mixin h6 {
    margin-bottom: 5px;
    color: $c-h6;
    font-family: $font-family-sans;
    font-size: $font-size-xs;
    font-style: normal;
    line-height: $line-height-s;
    text-transform: uppercase;
    @include bold;
}

@mixin hyphenate {
    word-wrap: break-word;
    hyphens: auto;
}

@mixin label {
    color: $c-label;
    font-family: $font-family-sans;
    font-size: $font-size-s;
    font-weight: $font-weight-semibold;
    font-style: normal;
    letter-spacing: $letter-spacing;
}

@mixin overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin price {
    font-size: $font-size-l;
    font-weight: $font-weight-semibold;
    line-height: 1;
    white-space: nowrap;
}

@mixin text {
    margin-bottom: 0;
    color: $c-text;
    font-family: $font-family-sans;
    font-size: $f-size;
    font-weight: normal;
    font-style: normal;
    line-height: $line-height-base;
    text-transform: none;
}
