/**
* Baker Distributing
*/


// ==============================================
// Action Skip
// ==============================================

.action.skip {
    &:not(:focus) {
        @include lib-visually-hidden;
    }

    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 15;
        width: 100%;
        padding: $space-base;
        background: $gray-50;
        text-align: center;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}
