/**
* Baker Distributing
*/


// ==============================================
// Animation - Gradient Loading
// ==============================================


@keyframes gradientLoading {
    from {
        background-position: 100% 0%;
    }
    to {
        background-position: 0% 100%;
    }
}