/**
* Baker Distributing
*/


// ==============================================
// Animation - Scale Up
// ==============================================


// ----------------------------------------------
// Top Left

@keyframes scale-up-topleft {
    0% {
        transform: scale(0.5);
        transform-origin: 0% 0%;
    }
    100% {
        transform: scale(1);
        transform-origin: 0% 0%;
    }
}