/**
* Baker Distributing
*/


// ==============================================
// Vars
// ==============================================

$dashboard-grid-gap: 40px;
$dashboard-grid-col-2-width: 280px;


// ==============================================
// Dashboard Accounts
// ==============================================

.dashboard-section_accounts {
    margin-bottom: $space-xxl;
    margin-right: $space-xl;
    height: 172px;
}

.dashboard-accounts {
    display: flex;
    flex-wrap: wrap;
    margin-top: -15px;
    margin-left: -15px;
    height: 148px;
}

.dashboard-accounts_item {
    margin-top: $space-base;
    margin-left: $space-base;
    padding: $space-s;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-white;
}

.dashboard-accounts_item p {
    margin-bottom: 10px;
}

// ==============================================
// Dashboard BakerPay
// ==============================================

.dashboard-section_bakerpay {
    flex: 0 1 250px;
    max-width: 250px;
    height: 132px;
    align-self: flex-end;
    position: relative;
    margin-bottom: $space-xxl;
}

.dashboard-bakerpay {
    padding: 26px $space-base;
    transition: $transition-all;
    box-shadow: $box-shadow-base;
}

.dashboard-bakerpay img {
    margin: 0 auto;
}

.dashboard-section_bakerpay a {
    color: $c-text;
}

.dashboard-section_bakerpay a:hover {
    text-decoration: none;
}

a:hover .dashboard-bakerpay {
    box-shadow: $box-shadow-hover;
}

.dashboard-bakerpay--flag {
    position: absolute;
    right: 0;
    top: 0;
}

// ==============================================
// Dashboard Account Profile
// ==============================================

.dashboard-section_accountprofile {
    margin-bottom: $space-xxl;
    margin-right: $space-xl;
    height: 172px;
}

.dashboard-accountprofile {
    display: flex;
    flex-wrap: wrap;
    margin-top: -15px;
    margin-left: -15px;
    height: 148px;
}

.dashboard-accountprofile_item {
    margin-top: $space-base;
    margin-left: $space-base;
    padding: $space-s;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-white;
    width: 185px;
    text-align: center;
}

.dashboard-accountprofile_item p {
    margin-bottom: 10px;
    text-align: center;
}
.dashboard-accountprofile_item svg {
    margin-top: -5px;
    margin-bottom: 10px;
}

// ==============================================
// Dashboard Grid
// ==============================================

@media (min-width: 1000px) {

    .dashboard-grid {
        overflow: hidden;
    }

    .dashboard-grid > .col-1 {
        float: left;
        width: calc(100% - #{($dashboard-grid-gap + $dashboard-grid-col-2-width)});
        max-width: 900px;
    }

    .dashboard-grid > .col-2 {
        float: right;
        width: ($dashboard-grid-gap + $dashboard-grid-col-2-width);
        padding-left: $dashboard-grid-gap;
    }

}


// ==============================================
// Dashboard Section
// ==============================================

.dashboard-section {
    margin-bottom: $space-xxl;
}

.dashboard-section--top {
    display: flex;
    flex-wrap: wrap;
}


// ==============================================
// Dashboard Quick Lists
// ==============================================

//.dashboard-quick-lists {}

.dashboard-quick-lists_item {
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }
}
