/**
* Baker Distributing
*/


// ==============================================
// Vars
// ==============================================

$password-meter-color-base:        $gray-50;
$password-meter-color-weak:        #ffafae;
$password-meter-color-medium:      #ffd6b3;
$password-meter-color-strong:      #c5eeac;
$password-meter-color-very-strong: #81b562;

$password-meter-height: 40px;


// ==============================================
// Password Strength Meter Container
// ==============================================

//.password-strength-meter-container {}


// ==============================================
// Password Strength Meter
// ==============================================

.password-strength-meter {
    position: relative;
    z-index: 1;
    height: $password-meter-height;
    padding: 0 1em;
    background-color: $password-meter-color-base;
    line-height: $password-meter-height;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        height: 100%;
    }
}

.password-none .password-strength-meter {
    &::before {
        width: 100%;
        background-color: $password-meter-color-base;
    }
}

.password-weak .password-strength-meter {
    &::before {
        width: 25%;
        background-color: $password-meter-color-weak;
    }
}

.password-medium .password-strength-meter {
    &::before {
        width: 50%;
        background-color: $password-meter-color-medium;
    }
}

.password-strong .password-strength-meter {
    &::before {
        width: 75%;
        background-color: $password-meter-color-strong;
    }
}

.password-very-strong .password-strength-meter {
    &::before {
        width: 100%;
        background-color: $password-meter-color-very-strong;
    }
}
