/**
* Baker Distributing
*/


// ==============================================
// Counter
// ==============================================

.counter {
    padding: 2px 8px;
    border-radius: $radius-base;
    background: $gray-100;
    color: $gray-700;
    font-size: $font-size-xs;
    white-space: nowrap;
}