/**
* Baker Distributing
*/

// ==============================================
// Display 1
// ==============================================

.display-1 {
    font-size: 96px;
    font-weight: $font-weight-light;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .display-1 {
        font-size: 60px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .display-1 {
        font-size: 36px;
    }
}

// ==============================================
// Display 2
// ==============================================


.display-2 {
    font-size: 60px;
    font-weight: $font-weight-light;
}

@media (max-width: $bp-screen-m - 1px) {
    .display-2 {
        font-size: 48px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .display-2 {
        font-size: 28px;
    }
}

// ==============================================
// Display 3
// ==============================================

.display-3 {
    font-size: 48px;
    font-weight: $font-weight-normal;
}

@media (max-width: $bp-screen-m - 1px) {
    .display-3 {
        font-size: 38px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .display-3 {
        font-size: 24px;
    }
}