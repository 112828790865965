/**
* Baker Distributing
*/


// ==============================================
// Select
// ==============================================

select {
    width: 100%;
    height: 38px;
    padding: 8px 20px 8px 0;
    border: 0;
    border-bottom: 1px solid $c-input-border-bottom;
    border-radius: 0;
    background: transparent;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23666464' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    background-origin: content-box;
    background-position: right -25px center;
    background-repeat: no-repeat;
    background-clip: padding-box;
    outline: 0;
    appearance: none;
    color: $c-text;
    vertical-align: baseline;
    transition: border ease-in-out 180ms;

    &:focus {
        border-bottom-color: $c-input-border-focus;
    }

    @include input-disabled;

    &:disabled {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23bebcbc' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    }

    &::-ms-expand {
        display: none;
    }
}

select option {
    color: $c-text;
}


// ==============================================
// Select ~ Label
// ==============================================
.control select ~ label {
    font-weight: $font-weight-semibold;
}

select ~ label {
    top: -12px;
    font-size: 13px;
}

select:focus ~ label {
    color: $c-label-focus;
}


// ==============================================
// Modifier: Size
// ==============================================

.select--s {
    max-width: $form-elem-width-s;
}

.select--xs {
    max-width: $form-elem-width-xs;
}

.select--xxs {
    max-width: $form-elem-width-xxs;
}


// ==============================================
// State: Validation Failed
// ==============================================

select.mage-error:focus ~ label {
    color: $c-input-focus-error;
}

select.mage-error,
select.mage-error:focus {
    border-color: $c-input-focus-error;
}


// ==============================================
// Context: .form--contrast
// ==============================================

.form--contrast select {
    color: $c-contrast-input-text;
    border-bottom-color: $c-contrast-input-border-bottom;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23e9e9e9' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");

    &:focus {
        border-bottom-color: $c-contrast-input-border-focus;
    }

    &:disabled {
        background-color: $gray-800;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23666464' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    }
}

.form--contrast select:focus ~ label {
    color: $c-contrast-label-focus;
}
