//
//  Icons variables from core
//  _____________________________________________

$icon__position            : before !default;
$icon__text-hide           : false !default;
$icon__height              : 26px !default;
$icon__width               : 26px !default;
$icon__margin              : '' !default;
$icon__vertical-align      : middle !default;

$icon-image__position-x    : 0 !default;
$icon-image__position-y    : 0 !default;

$icon-sprite__position-x   : 0 !default;
$icon-sprite__position-y   : 0 !default;
$icon-sprite__grid         : 26px !default;

$icon-font                 : $font-family-icon !default;
$icon-font__position       : $icon__position !default;
$icon-font__text-hide      : $icon__text-hide !default;
$icon-font__margin         : $icon__margin !default;
$icon-font__size           : inherit !default;
$icon-font__line-height    : $icon-font__size !default;
$icon-font__color          : inherit !default;
$icon-font__color-hover    : false !default;
$icon-font__color-active   : false !default;
$icon-font__vertical-align : $icon__vertical-align !default;
$icon-font__display        : inline-block !default;

$icon-calendar__font-size  : 40px !default;

//
// Variables changed from the default values before removing snowdog as parent theme
//____________________________________________________________________________________

$icon-font                  : $font-family-icon;
$icon-font__size            : 24px;
$icon-font__line-height     : 20px;

//
//  Icons
//  _____________________________________________

$icon-add: '\e145';
$icon-arrow-down: '\e5db';
$icon-arrow-down-thin: '\e313';
$icon-arrow-dropdown: '\e5c5';
$icon-arrow-left-thin: '\e5c4';
$icon-arrow-right-thin: '\e5c8';
$icon-arrow-up: '\e5d8';
$icon-arrow-up-thin: '\e316';
$icon-edit: '\e254';
$icon-calendar: '\e878';
$icon-cart: '\e8cc';
$icon-checkmark: '\e5ca';
$icon-close: '\e5cd';
$icon-collapse: '\e5ce';
$icon-comment: '\e0b9';
$icon-comment-reflected: '\e0b9';
$icon-down: '\e313';
$icon-envelope: '\e0be';
$icon-error: '\e000';
$icon-expand: '\e145';
$icon-flag: '\e153';
$icon-grid: '\e8f0';
$icon-help: '\e887';
$icon-info: '\e88e';
$icon-location: '\e0c8';
$icon-more: 'more_vert';
$icon-mylocation: '\e55c';
$icon-list: '\e8ef';
$icon-menu: '\e5d2';
$icon-dollar: '\e227';
$icon-next: '\e315';
$icon-phone: '\e0cd';
$icon-pointer-down: '\e313';
$icon-pointer-up: '\e316';
$icon-pointer-right: '\e315';
$icon-pointer-left: '\e314';
$icon-present: '\e8f6';
$icon-prev: '\e314';
$icon-remove: '\e5cd';
$icon-search: '\e8b6';
$icon-settings: '\e8b8';
$icon-star: '\e838';
$icon-success: '\e86c';
$icon-time: '\e192';
$icon-trash: '\e872';
$icon-up: '\e316';
$icon-update: '\e863';
$icon-warning: '\e002';
$icon-wishlist-empty: '\e03B';

//Baker Layout icons
$icon-store: '\e8D1';
$icon-shipping: '\e558';

//Customer Section icons
$icon-dashboard: '\e871';
$icon-quotes: '\e8B0';
$icon-quicklists: '\e03B';
$icon-orders: '\e558';
$icon-statements: '\e14D';
$icon-invoices: '\e870';
$icon-user: '\e7fd';
$icon-users: '\e8D3';
$icon-company: '\e0AF';

// Magento_Company
$icon-circle-down: '\e909';
$icon-circle-up: '\e90a';
$icon-customer-lead: '\e908';
$icon-move-vertical: '\e90b';
$icon-organization: '\e906';
$icon-group:  'group';
$icon-person: 'person';

$icon-inventory-available: '\e86c';
$icon-inventory-unavailable: '\e5c9';


//Baker loading icon - use as background-image
$icon-loading: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzAuNCAzMC40Ij48c3R5bGU+LnN0MHtmaWxsOiNjODEwMmV9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNLTMzNS02MTMuOGgzLjNjMC02IDQuOS0xMC45IDEwLjktMTAuOSAzLjMgMCA2LjQgMS41IDguNSA0LjFsMi42LTIuMWMtMi43LTMuMy02LjctNS4zLTExLjEtNS4zLTcuOSAwLTE0LjIgNi4zLTE0LjIgMTQuMnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMzNiA2MjkpIi8+PHBhdGggY2xhc3M9InN0MCIgZD0iTS0zMDcuNy02MTguM2MuMS4zLS4yLjUtLjUuNGwtNi41LTEuNGMtLjMtLjEtLjMtLjMtLjEtLjRsNi41LTUuMmMuMi0uMi40LS4xLjQuMmwuMiA2LjR6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYgNjI5KSIvPjxnPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zNjYuNiA2NjUuOGgzLjNjMC02IDQuOS0xMC45IDEwLjktMTAuOSAzLjMgMCA2LjQgMS41IDguNSA0LjFsMi42LTIuMWMtMi43LTMuMy02LjctNS4zLTExLjEtNS4zLTcuOSAwLTE0LjIgNi4zLTE0LjIgMTQuMnoiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMTk4IDM0MC41KSIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOTMuOSA2NjEuM2MuMS4zLS4yLjUtLjUuNGwtNi41LTEuNGMtLjMtLjEtLjMtLjMtLjEtLjRsNi41LTUuMmMuMi0uMi40LS4xLjQuMmwuMiA2LjR6IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDE5OCAzNDAuNSkiLz48L2c+PC9zdmc+);
$icon-loading--blue: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MiA1MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTIgNTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMzM4MGVmO30KPC9zdHlsZT4KPGc+Cgk8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzQwLjAwMDAwMCwgLTYyOS4wMDAwMDApIj4KCQk8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCA2MjkuMDAwMDAwKSI+CgkJCTxnPgoJCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTTQsMjZoNmMwLTExLDktMjAsMjAtMjBjNi4xLDAsMTEuOCwyLjgsMTUuNiw3LjVsNC43LTMuOEM0NS40LDMuNiwzOCwwLDMwLDBDMTUuNiwwLDQsMTEuNiw0LDI2eiIvPgoJCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTTU0LDE3LjhjMC4xLDAuNS0wLjQsMC45LTAuOSwwLjhsLTExLjktMi41Yy0wLjUtMC4xLTAuNi0wLjUtMC4yLTAuOGwxMS45LTkuNmMwLjQtMC4zLDAuOC0wLjIsMC44LDAuNAoJCQkJCUw1NCwxNy44eiIvPgoJCQk8L2c+CgoJCQkJPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIuMDAwMDAwLCAzOS4wMDAwMDApIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0zMi4wMDAwMDAsIC0zOS4wMDAwMDApIHRyYW5zbGF0ZSg0LjAwMDAwMCwgMjYuMDAwMDAwKSI+CgkJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNCwyNmg2YzAtMTEsOS0yMCwyMC0yMGM2LjEsMCwxMS44LDIuOCwxNS42LDcuNWw0LjctMy44QzQ1LjQsMy42LDM4LDAsMzAsMEMxNS42LDAsNCwxMS42LDQsMjZ6Ii8+CgkJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTQsMTcuOGMwLjEsMC41LTAuNCwwLjktMC45LDAuOGwtMTEuOS0yLjVjLTAuNS0wLjEtMC42LTAuNS0wLjItMC44bDExLjktOS42YzAuNC0wLjMsMC44LTAuMiwwLjgsMC40CgkJCQkJTDU0LDE3Ljh6Ii8+CgkJCTwvZz4KCQk8L2c+Cgk8L2c+CjwvZz4KPC9zdmc+Cg==);



    //Baker StockPro - use as background-image
$icon-qr-code: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTMsMTFoMnYySDNWMTEgTTExLDVoMnY0aC0yVjUgTTksMTFoNHY0aC0ydi0ySDlWMTEgTTE1LDExaDJ2Mmgydi0yaDJ2MmgtMnYyaDJ2NGgtMnYyaC0ydi0yaC00djJoLTJ2LTRoNHYtMgoJaDJ2LTJoLTJWMTEgTTE5LDE5di00aC0ydjRIMTkgTTE1LDNoNnY2aC02VjMgTTE3LDV2MmgyVjVIMTcgTTMsM2g2djZIM1YzIE01LDV2MmgyVjVINSBNMywxNWg2djZIM1YxNSBNNSwxN3YyaDJ2LTJINXoiLz4KPC9zdmc+Cg==);


// Forms
$icon-indeterminate: '\e909';
$icon-checkbox: '\e834';
$icon-checkbox-outline: '\e835';