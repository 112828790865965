/**
* Baker Distributing
*/


// ==============================================
// List
// ==============================================


.list {
    list-style: disc outside none;
    margin-left: $space-base;
}