/**
* Baker Distributing
*/

// ==============================================
// Inventory Finder - Location
// ==============================================

.inventory-finder_location {
    @include lib-icon-font(
                    $icon-mylocation,
            $_icon-font-size: 18px,
            $_icon-font-position: before,
            $_icon-font-margin: -3px 6px 0 0
    )
}


// ==============================================
// Inventory Finder - Product
// ==============================================

.inventory-finder_product {
    display: flex;
    align-items: center;
    background: $c-background;
    padding: $space-xs $space-base;
}

.inventory-finder_image {
    margin-right: 16px;
}

@media (max-width: $bp-screen-s - 1px) {
    .inventory-finder_image {
        display: none;
    }
}

// ==============================================
// Inventory Finder - Results
// ==============================================

.inventory-finder_results {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.inventory-finder_results tr {
    cursor: pointer;
}

// ==============================================
// Inventory Finder - List
// ==============================================

.inventory-finder_list {
    width: 60%;
}

@media (max-width: $bp-screen-m - 1px) {
    .inventory-finder_list {
        width: 100%;
        order: 2;
    }
}

// ==============================================
// Inventory Finder - Map
// ==============================================

.inventory-finder_map {
    padding-left: 16px;
    width: 40%;
}

@media (max-width: $bp-screen-m - 1px) {
    .inventory-finder_map {
        width: 100%;
        order: 1;
        margin-bottom: $margin-bottom;
        padding-left: 0;
    }
}

.inventory-finder_map-container {
    position:sticky;
    top: 8px;

    > div {
        width: 100%;
        height: 400px;
    }
}

@media (max-width: $bp-screen-m - 1px) {

    .inventory-finder_map-container {
        height: 50px;
        position: static;
        overflow: hidden;
        transition: all .5s;
    }

    .inventory-finder_map-container.expanded {
        height: 400px;
    }
}


.inventory-finder_map-container ~ .inventory-finder_map-control {
    width: 100%;
    height: 30px;
    background: $c-primary;
    text-align: center;
    cursor: pointer;

    @include lib-icon-font(
                    $icon-down,
            $_icon-font-color: $c-white,
            $_icon-font-margin: 6px 0 0 0
    );

    display: none;
}

@media (max-width: $bp-screen-m - 1px) {
    .inventory-finder_map-container ~ .inventory-finder_map-control {
        display: block;
    }

    .inventory-finder_map-container.expanded ~ .inventory-finder_map-control {
        @include lib-icon-font(
                        $icon-up,
                $_icon-font-color: $c-white,
                $_icon-font-margin: 6px 0 0 0
        );
        display: block;
    }
}

// ==============================================
// Inventory Finder Table
// ==============================================


.inventory-table-desktop {
    display: block;
}

@media (max-width: $bp-screen-m - 1px) {
    .inventory-table-desktop {
        display: none;
    }
}

.inventory-table-mobile {
    display: none;
}

@media (max-width: $bp-screen-m - 1px) {
    .inventory-table-mobile {
        display: block;
    }
}

.inventory-table_control {
    width: 10%;
}

.inventory-table_title {
    width: 50%;
}
@media (max-width: $bp-screen-s - 1px) {

    .inventory-table_title {
        width: 60%;
    }
}

.inventory-table .collapsible-table_hide {
    background: $c-background;
}

.inventory-table_status {
    white-space: nowrap;
}

.inventory-table_status,
.inventory-table_distance {
    width: 20%;
}

@media (max-width: $bp-screen-s - 1px) {

    .inventory-table_status {
        width: 30%;
    }
}

.inventory-table_available {
    color: $c-inventory-available;
    font-size: $font-size-l;
}

.inventory-table_unavailable {
    color: $c-inventory-unavailable;
    font-size: $font-size-l;
}

span.inventory-table_distance {
    display: none;
    width: auto;
}

@media (max-width: $bp-screen-s - 1px) {
    td.inventory-table_distance,
    th.inventory-table_distance {
        display: none;
    }

    span.inventory-table_distance {
        display: block;
    }
}

.inventory-finder_product img {
    max-width: auto;
}