.RegistrationSteps {
    text-align: center;
    overflow: visible;
    max-width: 400px;
    margin: 0 auto 50px;
    position: relative;
}
.RegistrationSteps::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    display: block;
    border-top: 1px solid;
    content: '';
    top: 25px;
}

.RegistrationSteps-list {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
}

.RegistrationSteps .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
}

.RegistrationSteps .step p {
    white-space: nowrap;
    text-transform: uppercase;
    margin-bottom: 0;
}

.RegistrationSteps .circle {
    position: relative;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border: 1px solid black;
    text-align:center;
    vertical-align: middle;
    border-radius: 50%;
    background: white;
    margin-bottom: 10px;
    font-size: 20px;
}

@media(max-width: 600px){
    .RegistrationSteps {
        justify-content: space-between;
        width: 73%;
    }

    .RegistrationSteps .step p{
        display: none;
    }

    .RegistrationSteps-Container .line {
        width: 71%;
    }
}
