/**
* Baker Distributing
*/


// ==============================================
// Print
// ==============================================

.catalog-product-compare-index .action.print {
    float: right;
    margin: 15px 0;
}


// ==============================================
// Comparison Table
// ==============================================

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;
}

.table-comparison .cell.label.remove,
.table-comparison .cell.label.product {
    span {
        @include lib-visually-hidden;
    }
}

.table-comparison .cell.label,
.table-comparison td:last-child {
    border-right: 1px solid $c-border;
}

.table-comparison .cell {
    padding: $space-base;
    width: 140px;
}

.table-comparison .cell .attribute.value {
    overflow: hidden;
    width: 100%;
}

.table-comparison .cell.product.info,
.table-comparison .cell.product.label {
    border-bottom: 1px solid $c-border;
}

.table-comparison .cell.label .attribute.label {
    display: block;
    width: 100%;
    word-wrap: break-word;
}

.table-comparison .cell.attribute {
    font-size: $font-size-s;
}

.table-comparison .cell.attribute img {
    height: auto;
    max-width: 100%;
}

.table-comparison .product-item-photo {
    display: block;
    margin: 0 auto $space-base;
}

.table-comparison .product-image-photo {
    margin-left: 0;
}

.table-comparison .product-item-actions,
.table-comparison .price-box,
.table-comparison .product.rating,
.table-comparison .product-item-name {
    display: block;
    margin: $space-base 0;
}

.table-comparison .product-addto-links {
    margin-top: $space-base;
}

.table-comparison .product-addto-links .action.toggle {
    padding: 0;
}

.table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right;
}

.table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: $space-base;
}

.table-comparison .action.tocart {
    white-space: nowrap;
}

// ==============================================
// Comparison . Headings
// ==============================================

.comparison.headings {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: auto;
    background: $c-white;
}


// ==============================================
// Block Compare
// ==============================================

.compare-wrapper {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: $c-secondary;
    padding: 10px 20px;
    width: 600px;
    box-shadow: 0px 0px 1px 0px $c-lightgray;
    flex-wrap: nowrap;
    z-index: 900; //Todo: change to the correct z-index group
}

.compare-wrapper--visible {
    display: flex;
}

.compare-wrapper .block-title {
    flex: 1 1 auto;
    order: 1;
    margin: 0;
    line-height: 36px;
    vertical-align: middle;
    color: $c-white;
    font-weight: 300;
    strong {
        color: $c-white;
    }
}

.compare-wrapper .actions-toolbar {
    flex: 0 0 238px;
    order: 2;
}

//Todo: show products added to compare in some sort of accordion
.compare-wrapper .block-content {
    display: none;
}

.block-compare .product-item {
    border: none;
    border-bottom: 1px solid $c-border;
    padding-bottom: 10px;

    &:hover {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid $c-border;
    }
}

.block-compare .product-item-name {
    margin-left: 22px;
}

.block-compare .product-item-name a {
    font-size: $font-size-s;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }

}
