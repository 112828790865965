/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Box Shadow
// ==============================================

$box-shadow-base:
    0 3px 1px -2px black(0.2),
    0 2px 2px 0 black(0.14),
    0 1px 5px 0 black(0.12) !default;

$box-shadow-hover:
    0 2px 4px -1px black(0.2),
    0 4px 5px 0 black(0.14),
    0 1px 10px 0 black(0.12) !default;

$box-shadow-inset: inset 0 2px 8px black(0.15) !default;

$box-shadow-thin:
    0 0 2px black(0.12),
    0 2px 4px black(0.24) !default;
