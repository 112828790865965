/**
* Baker Distributing
*/

// ==============================================
// Tooltip
// ==============================================

.tooltip {
    position: relative;
    transition: all .2s ease;
    cursor: pointer;
    display: inline-block;

    &:after {
        background: $gray-700;
        color: $c-white;
        font-size: $font-size-xs;
        font-weight: $font-weight-normal;
        text-align: left;
        text-transform: none;
        pointer-events: none;
        border-radius: $radius-base;

        max-width: 200px;
        padding: $space-xs;

        content: attr(data-tooltip);
        position: absolute;
        width: max-content;
        min-width: 200px;
        left: 50%;
        top: 0;
        opacity: 0;
        z-index: $z-index-2;
        white-space: normal;

        transform: translate3d(-50%, 0, 0);
        transition: all .2s ease;
        transition-delay: .1s;
    }
    &:hover {
        &:after {
            transform: translate3d(-50%, $space-xxl, 0);
            opacity: 1;
        }
    }
}

// ----------------------------------------------
// Modifier: Tooltip with icon
// ----------------------------------------------

.tooltip--icon > i {
    font-size: $font-size-xxl;
    color: $c-icon-base;
}

.tooltip--icon i:hover {
    color: $c-text;
}

// ----------------------------------------------
// Modifier: Right

.tooltip--right {
    &:after {
        left: 0;
        transform: translate3d(0%, 0, 0);
    }

    &:hover {
        &:after {
            transform: translate3d(0%, calc(50% + #{$space-xs}), 0);
        }
    }
}


// ----------------------------------------------
// Modifier: Left

.tooltip--left {

    &:after {
        left: 100%;
        transform: translate3d(-100%, 0, 0);
    }

    &:hover {
        &:after {
            transform: translate3d(-100%, calc(50% + #{$space-xs}), 0);
        }
    }
}
