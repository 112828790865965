/**
* Baker Distributing
*/


// ==============================================
// Animation - Rotate Clockwise
// ==============================================

@keyframes rotate-clockwise {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes rotate-left {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}

@keyframes rotate-half--right {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(180deg);
    }
}

@keyframes rotate-half--left {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-180deg);
    }
}
