/**
* Baker Distributing
*/


// **********************************************
// [todo] determine where this renders.
// **********************************************


// ==============================================
// Block Cart Failed
// ==============================================

.block-cart-failed {
    margin-top: $space-xxxl;
}

.block-cart-failed .actions {
    text-align: center;
}

.block-cart-failed .action {
    margin-bottom: $space-base;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .block-cart-failed {
        @extend .abs-shopping-cart-items-desktop;
    }

    .block-cart-failed .actions {
        text-align: left;
    }

    .block-cart-failed .actions.primary {
        float: right;
    }

    .block-cart-failed .block-content {
        @include lib-clearfix;
    }

}
