/**
* Baker Distributing
*/

// ==============================================
// Vars
// ==============================================


$sidebar-width: 200px;
$bp-product-view-heading-stack: 700px;

// ==============================================
// Column . Main
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .catalog-product-view .column.main {
        display: flex;
        flex-direction: column;
    }

}


// ==============================================
// Product View - Col 1
// ==============================================

.product-view_col1 {
    flex: 1 1 auto;
}

.product-view_col1 .product-attribute {
    display: inline-block;
}

.product-view_col1 .product-attribute .mfg {
    margin-left: $space-l;
}

.product-view_col1 .page-title-wrapper .page-title {
    margin-bottom: 0;
}

.product-view_col1 .product.photo .photo.image {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}

.product-view_col1 .placeholder .photo.container {
    max-width: 100%;
}

.product-view_col1 .notice {
    margin: $space-base 0;
    color: $c-text-muted;
    font-size: $font-size-xs;
}

.product-view_col1 .product.thumbs {
    margin: $space-l 0 $space-xxl;
}

.product-view_col1 .items.thumbs > li {
    display: inline-block;
    vertical-align: top;
}

.product-view_col1 .items.thumbs .active {
    display: block;
    line-height: 1;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .product-view_col1 {
        order: -1;
    }

}


// ==============================================
// Product View
// ==============================================

.product-view {
    display: flex;
    flex-wrap: wrap;
    max-width: 1280px;
    margin: 0 auto;
}

// ----------------------------------------------

@media (min-width: $bp-product-view-heading-stack) {
    .product-view {
        flex-wrap: nowrap;
        margin-bottom: 20px;
    }
}

// ==============================================
// Product View - Inventory
// ==============================================

.product-view .inventory {
    height: 120px;
}

// ==============================================
// Product View - Social Links
// ==============================================

.product-social-links {
    border-bottom: 1px solid $c-border;
    padding: $space-base 0;
    margin-bottom: $space-base;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .product-add-form {
        margin: 0;
    }

}


// ==============================================
// Product . Attribute . Overview
// ==============================================

.product.attribute.overview {
    margin-bottom: $space-base;
    padding-bottom: 10px;
    border-bottom: 1px solid $c-border;
}


// ==============================================
// Product Info Main
// ==============================================

.product-view_col2 {
    flex: 1 0 285px;
}

.product-attribute {
    display: block;
}

.product-attribute--inline {
    display: inline-block;
}

.product-attribute_value {
    display: inherit;
    word-break: break-all;
    margin-right: $space-base;
    &:last-child {
        margin-right: 0;
    }
}

.product-attribute_value.description {
    word-break: break-word;
}

.product-attribute_value.sku,
.product-attribute_value.number,
.product-attribute_value.mfg {
    color: $c-text-muted-dark;
    font-size: $font-size-s;
}

.product-view_col2 .product.alert {
    margin: $space-base 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .product-view_col2 {
        padding-left: $space-base;
    }

}


// ==============================================
// Product Details
// ==============================================

.product-details {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
}


// ==============================================
// Product Details Sidebar
// ==============================================

.catalog-product-view .content-wrapper {
    height: auto;
}

.product-details_sidebar {
    position: sticky;
    top: 16px;
    float: right;
    width: $sidebar-width;
    background: $c-white;
    box-shadow: $box-shadow-thin;
    padding: $space-base;
}

.product-details_sidebar--ie {
    position: absolute;
    right: 0;
    float: none;
}


@media (max-width: $bp-screen-m - 1px) {
    .product-details_sidebar {
        display: none;
    }
}

.product-details_sidebar a {
    display: block;
    padding: $space-xxs 0;
}


@media (min-width: $bp-screen-m) {
    .product-details_wrapper {
        margin-right: calc(#{$sidebar-width} + #{$space-base});
    }
}


// ==============================================
// Product Options Wrapper
// ==============================================

.product-options-wrapper .fieldset-product-options-inner .legend {
    display: inline-block;
    float: none;
    margin: 0 0 $space-xs;
    padding: 0;
    border: 0;
    font-weight: $font-weight-bold;
}

//  Date & Time custom option (Affect Time that goes only after Date)
.product-options-wrapper .fieldset-product-options-inner input.datetime-picker ~ select.datetime-picker {
    margin-top: $space-base;
}

.product-options-wrapper .fieldset-product-options-inner.required .legend,
.product-options-wrapper .fieldset-product-options-inner._required .legend {
    &::after {
        content: '*';
        margin: $form-field-label-asterisk__margin;
    }
}

.product-options-wrapper .field .note {
    display: block;
}

.product-options-wrapper .field .price-notice {
    @extend .abs-adjustment-incl-excl-tax;
}


// ==============================================
// Box To Cart
// ==============================================

.product-view_col2 .box-tocart .fieldset,
.product-options-bottom .box-tocart .fieldset {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.product-view_col2 .box-tocart .field.qty,
.product-options-bottom .box-tocart .field.qty {
    padding-right: 0.75 * $space-l;
    flex: 0 0 50px;
    margin-top: 0px;
}

.product-view_col2 .box-tocart .input-text.qty,
.product-options-bottom .box-tocart .input-text.qty {
    text-align: center;
    width: 50px;
}

.product-view_col2 .box-tocart .actions,
.product-options-bottom .box-tocart .actions {
    text-align: left;
    flex: 1 1 auto;
    margin-top: 9px;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .product-view_col2 .box-tocart .actions {
        text-align: center;
        vertical-align: bottom;
    }

    .product-view_col2 .box-tocart,
    .product-options-bottom .box-tocart {
        display: table;
    }

    .product-view_col2 .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
        display: table-cell;
    }

    .product-view_col2 .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
        display: table-cell;
        text-align: center;
        vertical-align: bottom;
    }

}


// ==============================================
// Block Related
// ==============================================

.block.related .action.select {
    margin: 0 $space-xs;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .block.related .action.select {
        display: block;
        margin: $space-xs 0;
    }

}


// ==============================================
// PDP Sidebar
// ==============================================

.sidebar .product-items .product-item {
    margin-bottom: $space-l;
    position: relative;
}

.sidebar .product-items .product-item-info {
    position: relative;
    width: auto;
}

.sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0;
}

.sidebar .product-items .product-item-name {
    margin-top: 0;
}

.sidebar .product-items .product-item-details {
    margin: 0 0 0 85px;
}

.sidebar .product-items .product-item-actions {
    display: block;
    margin-top: $space-base;
}

.sidebar .product-items .price-box {
    display: block;
    margin: 7px 0;
}

.sidebar .product-items .text {
    margin-right: 8px;
}

.sidebar .product-items .counter {
    color: $c-primary;
    font-size: $font-size-xs;
    white-space: nowrap;
}

.sidebar .product-items .minilist .price {
    display: inline;
    padding: 0;
}

.sidebar .product-items .minilist .weee::before {
    display: inline-block;
}

.sidebar .action.delete {
    position: absolute;
    right: 0;
    top: 0;
}

.sidebar .subtitle {
    display: none;
}

// ----------------------------------------------
//  Product images only

.sidebar .product-items-images {
    @include lib-clearfix;
    margin-left: -$space-xs;
}

.sidebar .product-items-images .product-item {
    float: left;
    padding-left: $space-xs;
}

// ----------------------------------------------
// Product names only

.sidebar .product-items-names .product-item {
    margin-bottom: $space-base;
}

.sidebar .product-items-names .product-item-name {
    margin: 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .sidebar .product-items .product-item-info .product-item-photo {
        float: left;
        left: auto;
        margin: 0 $space-base $space-base 0;
        position: relative;
        top: auto;
    }

    .sidebar .product-items .product-item-details {
        margin: 0;
    }

    .sidebar .product-items .product-item-actions {
        clear: left;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-xl) {

    .sidebar .product-items .product-item-info .product-item-photo {
        float: none;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
    }

    .sidebar .product-items .product-item-details {
        margin-left: 85px;
    }

}

.additional-attributes th {
    white-space: normal;
}
