/**
* Baker Distributing
*/


// ==============================================
// Shipping Information
// ==============================================

.step-summary {
    position: relative;
    margin-bottom: $space-xxl;
    color: $c-text-muted;
}

.step-summary .step-title {
    color: $c-text-muted;
}

.step-summary_action {
    position: absolute;
    right: 0;
    top: 8px;
}


