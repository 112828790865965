.Success {
    text-align: center;
}

.Success p {
    font-size: 24px;
    color: var(--baker-red);
    margin: 0 0 100px 0;
}

.Success .Success-button-container {
    margin-top: 40px;
    text-align: center;
}