//
//  Navigation
//  _____________________________________________

@mixin lib-main-navigation(
    $_nav__indent-side                        : $navigation__indent-side,
    $_nav-background-color                    : $navigation__background,
    $_nav-level0-font-size                    : $navigation-level0-item__font-size,
    $_nav-level0-font-weight                  : $navigation-level0-item__font-weight,
    $_nav-level0-item-margin                  : $navigation-level0-item__margin,
    $_nav-level0-text-transform               : $navigation-level0-item__text-transform,
    $_nav-level0-item-border                  : $navigation-level0-item__border,
      $_nav-level0-item-padding                  : $navigation-level0-item__padding,
      $_nav-level0-item-color                   : $navigation-level0-item__color,
    $_nav-level0-item-text-decoration         : $navigation-level0-item__text-decoration,

    $_nav-level0-item-background-color-active : $navigation-level0-item__active__background,
    $_nav-level0-item-color-active            : $navigation-level0-item__active__color,
    $_nav-level0-item-text-decoration-active  : $navigation-level0-item__active__text-decoration,

    $_submenu-background-color                : $submenu__background,
    $_submenu-font-size                       : $submenu__font-size,
    $_submenu-font-weight                     : $submenu__font-weight,
    $_submenu-item__padding-left              : $submenu__padding-left,

    $_submenu-item-background-color           : $submenu-item__background,
    $_submenu-item-border                     : $submenu-item__border,
    $_submenu-item-color                      : $submenu-item__color,
    $_submenu-item-text-decoration            : $submenu-item__text-decoration,

    $_submenu-item-background-color-active    : $submenu-item__active__background,
    $_submenu-item-color-active               : $submenu-item__active__color,
    $_submenu-item-text-decoration-active     : $submenu-item__active__text-decoration
) {
    background: $_nav-background-color;
    border-bottom: $_nav-level0-item-border;
    letter-spacing: 0.04em;
    ul, ol {
        margin: 0;
        padding: 0;
        list-style: none none;
        font-size: $_nav-level0-font-size;
        li {
            margin: $_nav-level0-item-margin;
            a {
                font-weight: $_nav-level0-font-weight;
                text-transform: $_nav-level0-text-transform;
                padding: $_nav-level0-item-padding;
                word-wrap: break-word;
            }
            &.active a,
            &.has-active a {
                color: $_nav-level0-item-color-active;
            }
        }
    }
    a {
        display: block;
        color: $_nav-level0-item-color;
        text-decoration: $_nav-level0-item-text-decoration;
    }

    .submenu {
        > li {
            word-wrap: break-word;
        }
        &:not(:first-child) {
            background: $_submenu-background-color;
            font-size: $_submenu-font-size;
            font-weight: $_submenu-font-weight;
            padding-left: $_submenu-item__padding-left;
            left: auto !important;
            overflow-x: hidden;
            position: relative;
            top: auto !important;
            transition: left 0.3s ease-out;

            > li {
                > a {
                    padding-left: $_nav__indent-side;
                }
            }

            ul, ol {
                display: block;
                > li {
                    margin: 0;

                    a {
                        background: $_submenu-item-background-color;
                        border: $_submenu-item-border;
                        color: $_submenu-item-color;
                        text-decoration: $_submenu-item-text-decoration;
                        display: block;
                        line-height: normal;
                    }
                }
            }

            &.expanded {
                display: block !important;
                padding-right: 0;
                top: 0 !important;
            }

            .active {
                > a {
                    background: $_submenu-item-background-color-active;
                    color: $_submenu-item-color-active;
                    padding-left: $_nav__indent-side;
                    text-decoration: $_submenu-item-text-decoration-active;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@mixin lib-main-navigation-desktop(
    $_nav-background-color                    : $navigation-desktop__background,
    $_nav-border                              : $navigation-desktop__border,
    $_nav-font-size                           : $navigation-desktop__font-size,
    $_nav-font-weight                         : $navigation-desktop__font-weight,

    $_nav-level0-item-line-height             : $navigation-desktop-level0-item__line-height,
    $_nav-level0-item-margin                  : $navigation-desktop-level0-item__margin,
    $_nav-level0-item-padding                 : $navigation-desktop-level0-item__padding,

    $_nav-level0-item-background-color        : $navigation-desktop-level0-item__background,
    $_nav-level0-item-border                  : $navigation-desktop-level0-item__border,
    $_nav-level0-item-color                   : $navigation-desktop-level0-item__color,
    $_nav-level0-item-text-decoration         : $navigation-desktop-level0-item__text-decoration,

    $_nav-level0-item-background-color-hover  : $navigation-desktop-level0-item__hover__background,
    $_nav-level0-item-border-hover            : $navigation-desktop-level0-item__hover__border,
    $_nav-level0-item-color-hover             : $navigation-desktop-level0-item__hover__color,
    $_nav-level0-item-text-decoration-hover   : $navigation-desktop-level0-item__hover__text-decoration,

    $_nav-level0-item-background-color-active : $navigation-desktop-level0-item__active__background,
       $_nav-level0-item-color-active            : $navigation-desktop-level0-item__active__color,
    $_nav-level0-item-text-decoration-active  : $navigation-desktop-level0-item__active__text-decoration,

    $_submenu-background-color                : $submenu-desktop__background,
    $_submenu-border-width                    : $submenu-desktop__border-width,
    $_submenu-border-style                    : $submenu-desktop__border-style,
    $_submenu-border-color                    : $submenu-desktop__border-color,
    $_submenu-box-shadow                      : $submenu-desktop__box-shadow,
    $_submenu-font-size                       : $submenu-desktop__font-size,
    $_submenu-font-weight                     : $submenu-desktop__font-weight,
    $_submenu-min-width                       : $submenu-desktop__min-width,
    $_submenu-padding                         : $submenu-desktop__padding,

    $_submenu-arrow                           : $submenu-desktop-arrow,
    $_submenu-arrow-size                      : $submenu-desktop-arrow__size,
    $_submenu-arrow-left                      : $submenu-desktop-arrow__left,

    $_submenu-item-padding                    : $submenu-desktop-item__padding,
    $_submenu-item-background-color           : $submenu-desktop-item__background,
    $_submenu-item-border                     : $submenu-desktop-item__border,
    $_submenu-item-color                      : $submenu-desktop-item__color,
    $_submenu-item-text-decoration            : $submenu-desktop-item__text-decoration,

    $_submenu-item__hover__background-color   : $submenu-desktop-item__hover__background,
    $_submenu-item-border-hover               : $submenu-desktop-item__hover__border,
    $_submenu-item-color-hover                : $submenu-desktop-item__hover__color,
    $_submenu-item-text-decoration-hover      : $submenu-desktop-item__hover__text-decoration,

    $_submenu-item-background-color-active    : $submenu-desktop-item__active__background,
    $_submenu-item-color-active               : $submenu-desktop-item__active__color,
    $_submenu-item-text-decoration-active     : $submenu-desktop-item__active__text-decoration
) {

    .navigation {
        background: $_nav-background-color;
        border: $_nav-border;
        font-size: $_nav-font-size;
        font-weight: $_nav-font-weight;
        height: inherit;
        left: auto;
        overflow: inherit;
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 3;
        letter-spacing: 0.04em;
        &:empty {
            display: none;
        }

        ul {
            margin-top: 0;
            margin-bottom: 0;
            padding: 10px 20px 0 0;
            position: relative;
        }

        li.level0 {
            border-top: none;
        }

        .level0 {
            margin: $_nav-level0-item-margin;
            display: inline-block;
            position: relative;

            &:last-child {
                margin-right: 0;
                padding-right: 0;
            }

            > .level-top {
                background: $_nav-level0-item-background-color;
                border: $_nav-level0-item-border;
                color: $_nav-level0-item-color;
                padding: $_nav-level0-item-padding;
                text-decoration: $_nav-level0-item-text-decoration;
                position: relative;

                &:hover,
                &.ui-state-focus {
                    background: $_nav-level0-item-background-color-hover;
                    border: $_nav-level0-item-border-hover;
                    color: $_nav-level0-item-color-hover;
                    text-decoration: $_nav-level0-item-text-decoration-hover;
                }
            }

            &.active,
            &.has-active {
                > .level-top {
                    background: $_nav-level0-item-background-color-active;
                    color: $_nav-level0-item-color-active;
                    text-decoration: $_nav-level0-item-text-decoration-active;
                    display: inline-block;
                }
            }

            &.parent:hover > .submenu {
                overflow: visible !important;
            }

            .submenu {
                background: $_submenu-background-color;
                border: $_submenu-border-width $_submenu-border-style $_submenu-border-color;
                box-shadow: $_submenu-box-shadow;
                font-size: $_submenu-font-size;
                font-weight: $_submenu-font-weight;
                min-width: $_submenu-min-width;
                padding: $_submenu-padding;
                display: none;
                left: 0;
                margin: 0 !important;
                padding: 0;
                position: absolute;
                z-index: 1;

                @include _lib-submenu-arrow(
                    $_submenu-arrow,
                    $_bg     : $_submenu-background-color,
                    $_border : $_submenu-border-color,
                    $_size   : $_submenu-arrow-size,
                    $_left   : $_submenu-arrow-left
                );

                a {
                    display: block;
                    line-height: inherit;
                    background: $_submenu-item-background-color;
                    border: $_submenu-item-border;
                    color: $_submenu-item-color;
                    padding: $_submenu-item-padding;
                    text-decoration: $_submenu-item-text-decoration;

                    &:hover,
                    &.ui-state-focus {
                        background: $_submenu-item__hover__background-color;
                        border: $_submenu-item-border-hover;
                        color: $_submenu-item-color-hover;
                        text-decoration: $_submenu-item-text-decoration-hover;
                    }
                }

                .active > a {
                    background: $_submenu-item-background-color-active;
                    color: $_submenu-item-color-active;
                    text-decoration: $_submenu-item-text-decoration-active;
                }

                .submenu {
                    top: 0 !important;
                    left: 100% !important;
                }

                .submenu-reverse {
                    left: auto !important;
                    right: 100%;
                }
            }

            &.more {
                position: relative;
                @include lib-icon-font(
                    $icon-pointer-down,
                    $_icon-font-size     : 26px,
                    $_icon-font-position : after
                );

                &::before {
                    display: none;
                }

                &::after {
                    cursor: pointer;
                    padding: 8px 12px;
                    position: relative;
                    z-index: 1;
                }

                &:hover > .submenu {
                    overflow: visible !important;
                }

                li {
                    display: block;
                }
            }
        }
    }
}

//  Submenu arrow
@mixin _lib-submenu-arrow(
    $_submenu-arrow,
    $_bg,
    $_border,
    $_size,
    $_left
){
    @if $_submenu-arrow == true {
        @if iscolor($_bg) and iscolor($_border) {
            $_outer-size: $_size + 1;
            $_outer-left: $_left - 1;
            margin-top: $_outer-size;

            > ul {
                margin-top: $_outer-size;

                &::before,
                &::after {
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                }

                &::before {
                    color: $_bg;
                    left: $_left;
                    top: -$_size*2;
                    @include lib-arrow(up, $_size, $_bg);
                    z-index: 4;
                }

                &::after {
                    @include lib-arrow(up, $_outer-size, $_border);
                    color: $_border;
                    left: $_outer-left;
                    top: -$_outer-size*2;
                    z-index: 3;
                }
            }
        }

        @if iscolor($_bg) and iscolor($_border) == false {
            margin-top: -$_size;

            > ul {
                margin-top: $_size;

                &::before {
                    @include lib-arrow(up, $_size, $_bg);
                    color: $_bg;
                    left: $_left;
                    top: -$_size*2;
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    z-index: 4;
                }
            }
        }

        @if iscolor($_border) and iscolor($_bg) == false {
            margin-top: -$_size;

            > ul {
                margin-top: $_size;

                &::before {
                    @include lib-arrow(up, $_size, $_border);
                    color: $_border;
                    left: $_left;
                    top: -$_size*2;
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    z-index: 4;
                }
            }
        }
    }
}
