/**
* Baker Distributing
*/


// ==============================================
// Checkout Success
// ==============================================


.checkout-onepage-success .column.main {
    width: 100%;
}

.checkout-success-actions {
    display: block;
}

.checkout-success-icon {
    font-size: 100px !important;
}

