/**
* Baker Distributing
*/


// ==============================================
// Block CMS Hierarchy Link
// ==============================================

.block-cms-hierarchy-link.widget {
    display: block;
    margin-bottom: $space-l;
}


// ==============================================
// Block CMS Hierarchy Link Inline
// ==============================================

.block-cms-hierarchy-link-inline.widget {
    margin: 0;
}
