/**
* Baker Distributing
*/

// ==============================================
// Tables - Content Align
// ==============================================

@media (min-width: $bp-table-linearize) {
    .not-linearized-align-right {
        text-align: right  !important;
    }
}