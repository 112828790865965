/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Breakpoint
// ==============================================

// Breakpoints are always defined as "min-width" values.
// Write "($bp-name - 1px)" to shield a "max-width" query.

$bp-screen-xl:  1600px;
$bp-screen-l:   1280px;
$bp-screen-m:   960px;
$bp-screen-s:   600px;
$bp-screen-xs:  480px;
$bp-screen-xxs: 320px;
