/**
* Baker Distributing
*/

// ==============================================
// Payment Methods
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .payment-methods {
        margin: 0 -15px;
    }

}

.payment-method .table-caption{
    display: none;
}


// ==============================================
// Payment Method
// ==============================================

//.payment-method {}


// ==============================================
// Payment Method Title
// ==============================================

.payment-method-title {
    border: 1px solid $c-border;
    padding: $space-xs $space-base;
    margin-bottom: $space-base;
    background: $c-background;
}

.payment-method-title .payment-icon {
    display: inline-block;
    margin-right: $space-xs;
    vertical-align: middle;
}

.payment-method-title .action-help {
    display: inline-block;
    margin-left: $space-xs;
}


// ==============================================
// Payment Method Content
// ==============================================

.payment-method-content {
    display: none;
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 16px;
    border: 1px solid $c-border;
    margin-bottom: $space-xxl;

    &::before {
        @include triangle($direction: up, $size: 12px, $color: $c-border);
        left: 14px;
        top: -12px;
    }

    &::after {
        @include triangle($direction: up, $size: 10px, $color: $c-white);
        left: 16px;
        top: -10px;
    }
}

._active .payment-method-content {
    display: inline-block;
}

.payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 $space-l;
}


// ==============================================
// Payment Method - Summary
// ==============================================

.payment-card {
    display: inline-flex;
}

.payment-card_info {
    padding-top: 6px;
}