/**
* Baker Distributing
*/


// ==============================================
// Wishlist Form - Actions Toolbar
// ==============================================

.form.wishlist.items .actions-toolbar {
    margin-left: 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .wishlist-index-index .main .form-wishlist-items .actions-toolbar {
        margin-left: 0;
    }
}

.product-items,
.products.list.items,
.table-comparison {
    .action.towishlist {
        @include lib-icon-font(
            $_icon-font-content        : '',
            $_icon-font-size           : 29px,
            $_icon-font-color          : $c-text-muted,
            $_icon-font-color-hover    : $c-link,
            $_icon-font-text-hide      : true,
            $_icon-font-vertical-align : middle,
            $_icon-font-line-height    :  24px
        );
        @include lib-icon-font-symbol(
            $icon-wishlist-empty
        );
    }
}


// ==============================================
// Table . Wishlist
// ==============================================

//.table.wishlist {}

.table.wishlist thead > tr > th {
    border-bottom: 0;
}

.table.wishlist tbody > tr:last-child > td {
    border-bottom: 1px solid $c-border;
}

.table.wishlist .product.name {
    display: inline-block;
    margin-bottom: $space-base;
}

.table.wishlist .box-tocart {
    margin: $space-base 0;
}

.table.wishlist .box-tocart .qty {
    vertical-align: middle;
}

.table.wishlist .col.item {
    width: 50%;
}

.table.wishlist .col.photo {
    max-width: 150px;
}

.table.wishlist .col.selector {
    max-width: 15px;
}

.table.wishlist textarea {
    margin: $space-base 0;
}

.table.wishlist .input-text.qty {
    margin-bottom: $space-base;
}

.table.wishlist .action.primary {
    vertical-align: top;
}

.table.wishlist .price {
    font-weight: $font-weight-bold;
}


// ==============================================
// Block Wishlist
// ==============================================

//.block-wishlist .counter {}

.block-wishlist .product-item-name {
    margin-right: $space-l;
}


// ==============================================
// Products Grid
// ==============================================

.products-grid.wishlist .product-item-photo {
    display: block;
    margin-bottom: $space-base;
}

.products-grid.wishlist .product-item-name {
    margin-top: 0;
}

.products-grid.wishlist .product-item .price-box {
    margin: 0;
}

.products-grid.wishlist .product-item .comment-box .label {
    @include lib-visually-hidden;
}

.products-grid.wishlist .product-item-comment {
    display: block;
    height: 42px;
    margin: $space-base 0;
}

.products-grid.wishlist .product-item-actions > * {
    margin-right: $space-base;

    &:last-child {
        margin-right: 0;
    }
}

.products-grid.wishlist .product-item .box-tocart input.qty {
    height: 32px;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .products-grid.wishlist .product-item {
        border-bottom: 1px solid $c-secondary;

        &:first-child {
            border-top: 1px solid $c-secondary;
        }
    }

}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .products-grid.wishlist {
        margin-bottom: $space-xxl;
        margin-right: -$space-base;
    }

    .products-grid.wishlist .product-item {
        padding: $space-l $space-base $space-l $space-l;
        position: relative;
    }

    .products-grid.wishlist .product-item-photo {
        float: left;
        margin-right: $space-l;
    }

    .products-grid.wishlist .product-item-name {
        font-size: $font-size-l;
    }

    .products-grid.wishlist .product-item-actions {
        display: block;
    }

    .products-grid.wishlist .product-item-actions .action {
        margin-right: $space-base;

        &:last-child {
            margin-right: 0;
        }
    }

    .products-grid.wishlist .product-item-actions .action.edit {
        float: left;
    }

    .products-grid.wishlist .product-item-actions .action.delete {
        float: right;
    }

    .products-grid.wishlist .product-item-actions .action.edit,
    .products-grid.wishlist .product-item-actions .action.delete {
        margin-top: 7px;
    }

    .products-grid.wishlist .product-item-name,
    .products-grid.wishlist .product-item-description,
    .products-grid.wishlist .product-item .price-box,
    .products-grid.wishlist .product-item-tooltip {
        margin-left: 95px;
    }

    .products-grid.wishlist .product-item .box-tocart {
        float: left;
        margin-right: $space-l;
    }

    .products-grid.wishlist .product-item .box-tocart .stock {
        margin-top: 7px;
    }

    .products-grid.wishlist .product-item .giftregisty-dropdown,
    .products-grid.wishlist .product-item .field.qty {
        display: none;
    }

    .products-grid.wishlist .product-image-container {
        max-width: 80px;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .wishlist-index-index .products-grid .product-item {
        margin-bottom: $space-l;
    }

    .wishlist-index-index .products-grid .product-item-actions {
        margin: 0;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .products-grid.wishlist .product-item-tooltip {
        display: inline-block;
    }

    .products-grid.wishlist .product-item-actions {
        margin: $space-base 0 0;
    }

    .products-grid.wishlist .product-item .fieldset .field.qty {
        margin-bottom: $space-base;
        padding-right: $space-base;
    }

    .products-grid.wishlist .product-item .fieldset .field.qty .label {
        width: auto;
    }

    .products-grid.wishlist .product-item.box-tocart .actions-primary {
        margin: 0;
    }

    .products-grid.wishlist .product-item.box-tocart .stock {
        margin: $space-l 0 0;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-l) {

    .wishlist-index-index .products-grid .product-items {
        margin: 0;
    }

    // [todo] rewrite with flexbox
    .wishlist-index-index .products-grid .product-item {
        margin-left: calc((100% - 4 * 24.439%) / 3);
        padding: 0;
        width: 24.439%;

        &:nth-child(4n + 1) {
            margin-left: 0;
        }
    }

}


// ==============================================
// Product Item Info
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .wishlist-index-index .product-item {
        width: 100%;
    }

    .wishlist-index-index .product-item-info {
        width: auto;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .wishlist-index-index .product-item-info {
        width: 240px;
    }

}
