/**
* Baker Distributing
*/


// ==============================================
// Current Store
// ==============================================

.header-store {
    display: flex;
    order: 8;
    align-items: center;
    margin: 0 0 0 $space-xxl;
    width: 318px;
    color: $c-contrast;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store {
        margin: 0;
        width: auto;
    }
}

.header-store_wrapper {
    margin: 0 0 0 auto;
}


// ==============================================
// Current Store - Container
// ==============================================

.header-store_container {
    width: auto;
    display: block;
    background: transparent;
    border: none;
}


// ==============================================
// Current Store - Toggle
// ==============================================

.header-store_toggle {
    width: 100%;
    position: relative;
    padding: 0 28px 0 40px;

    @include lib-icon-font(
            $_icon-font-content: $icon-location,
            $_icon-font-size: 36px,
            $_icon-font-line-height: 36px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $c-white
    );
}

.header-store_toggle:after {
    margin-top: 5px;
}

@media (max-width: $bp-screen-m - 1px) {

    .header-store_toggle {
        width: 100%;
        position: relative;
        margin-right: 8px;

        @include lib-icon-font(
                $_icon-font-content     : $icon-location,
                $_icon-font-size        : 32px,
                $_icon-font-line-height : 30px,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : before,
                $_icon-font-display     : block,
                $_icon-font-color       : $c-white
        );
    }

    .header-store_container .dropdown-trigger:after {
        content: none;
    }

    .header-store_toggle.active:after {
        @include triangle($direction: down, $size: 8px, $color: $c-header-background);
        top: 20px;
        right: 12px;
        z-index: $z-index-10;
    }

    .header-store_dropdown {
        top: 21px
    }

    .header-store_container .dropdown-content:before,
    .header-store_container .dropdown-content:after {
        border: none;
    }

}

.header-store_toggle p {
    margin: 0;
    font-weight: 400;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_toggle p {
        display: none;
    }
}

.header-store_toggle::before {
    color: $c-white;
    position: absolute;
    left: 0;
}

@media (max-width: $bp-screen-m - 1px) {

    .header-store_toggle::before {
        left: auto;
        top: -15px;
    }

}

.header-store_toggle::after {
    position: absolute;
    right: 0;
    top: 14px;
}


// ==============================================
// Current Store - Dropdown
// ==============================================

.header-store_dropdown {
    display: none;
    padding: 20px;
    color: $c-text;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_dropdown {
        right: 0;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .header-store_wrapper .dropdown {
        position: static;
    }

    .header-store_wrapper .dropdown-content {
        width: 100%;
        left: 0;
        right: 0;
        top: 44px;
    }
}

.header-store_container.active .header-store_dropdown {
    display: block;
}

.header-store_dropdown::before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -8px;
    right: 22px;
}

.header-store_dropdown::after {
    @include triangle($direction: up, $size: 6px, $color: $c-white);
    top: -6px;
    right: $space-xl;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_dropdown::after,
    .header-store_dropdown::before {
        border: none;
    }
}


// ==============================================
// Current Store - Name
// ==============================================

@media (min-width: $bp-screen-m) {
    .store-name {
        white-space: nowrap;
    }
}


// ==============================================
// Current Store - Hours
// ==============================================

.store-hours {
    padding: 10px 0;
}

.store-hours .open {
    color: $green-500;
}

.store-hours .closed {
    color: $c-red;
}


// ==============================================
// Current Store - Distance
// ==============================================

.store-distance {
    padding-bottom: $space-base;
}


// ==============================================
// Current Store - Address
// ==============================================

.store-address {
    padding: 10px 0 0;
}