@import-normalize;

:root {
  --ecm-blue: #004B87;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  background: #f4f4f4;
  color: rgba(0, 0, 0, 87%);
}

button, input, table, textarea {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
}

h1 {
    font-size: 36px;
}
h1 + h1 {
    margin-top: -0.42em;
}
h2 + h2 {
    margin-top: -0.3em;
}

.button img {
  vertical-align: top;
}

.button, .button > span {
  display: inline-block;
}

.button.light:hover > span {
  background-color: rgba(0,0,0,4%);
}
.button.dark:hover > span {
  background-color: rgba(255,255,255,8%);
}

.button.light:focus > span {
  background-color: rgba(0,0,0,4%);
}
.button.dark:focus > span {
  background-color: rgba(255,255,255,24%);
}

.registration-guide {
    font-size: 24px;
}
