/**
* Baker Distributing
*/


// ==============================================
// Simple Table
// ==============================================

.table .simple-table,
.simple-table {
    margin-bottom: $gap;
}

.table .simple-table th,
.simple-table th {
    @include bold;
    color: $c-text-muted;
}

.table .simple-table th,
.table .simple-table td,
.simple-table th,
.simple-table td {
    padding-right: 1.75em;
    padding-bottom: 0.5em;
    line-height: $line-height-xxs;
    vertical-align: top;

    &:last-child {
        padding-right: 0;
    }
}

.table .simple-table tr:last-child th,
.table .simple-table tr:last-child td,
.simple-table tr:last-child th,
.simple-table tr:last-child td {
    padding-bottom: 0;
}
