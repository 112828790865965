/**
* Baker Distributing
*/


// ==============================================
// Global
// ==============================================

body {
    background: #FFF;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

}


// ==============================================
// Page Wrapper
// ==============================================

.page-wrapper {
    min-width: $bp-screen-xxs;
}

@media (min-width: $bp-screen-m) {

    .page-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%; // Stretch content area for sticky footer
    }

    .ie10 .page-wrapper,
    .ie11 .page-wrapper {
        height: 100%;
    }

    .page-wrapper > .breadcrumbs,
    .page-wrapper > .top-container,
    .page-wrapper > .widget {
        width: 100%;
    }

}


// ==============================================
// Trim
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .block.category.event,
    .footer.content,
    .header.content,
    .page-main,
    .page-wrapper > .breadcrumbs,
    .page-wrapper > .page-bottom,
    .page-wrapper > .page.messages,
    .page-wrapper > .widget,
    .top-container {
          padding-left: $layout__width-xs-indent;
          padding-right: $layout__width-xs-indent;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .block.category.event,
    .footer.content,
    .page-main,
    .page-wrapper > .breadcrumbs,
    .page-wrapper > .page-bottom,
    .page-wrapper .page.messages,
    .page-wrapper > .widget,
    .top-container {
          padding-left: $layout-indent__width;
          padding-right: $layout-indent__width;
    }

}
