/**
* Baker Distributing
*/


// ==============================================
// Bundle Actions
// ==============================================

.bundle-actions {
    margin: $space-base 0;
}


// ==============================================
// Bundle Options Container
// ==============================================

.bundle-options-container {
    clear: both;
    margin-bottom: $space-xxl;
}

.bundle-options-container .legend.title {
    @include h2;
}

.bundle-options-container .product-add-form {
    display: none;
}

.bundle-options-container .product-options-wrapper {
    margin-bottom: $space-xxl;
}

.bundle-options-container .action.back {
    margin-bottom: $space-xxl;
}

// [todo] change to modifier of price-box
.bundle-options-container .price-box .price {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
}

.bundle-options-container .price-notice {
    @extend .abs-adjustment-incl-excl-tax;
}

.bundle-options-container .block-bundle-summary {
    padding: $space-base $space-l;
    background: $c-secondary;
}

.bundle-options-container > .title > strong {
    @include h2;
}

.bundle-options-container .bundle-summary {
    margin-top: $space-xxl;
}

.bundle-options-container .bundle-summary.empty {
    display: none;
}

.bundle-options-container .bundle-summary > .subtitle {
    @include h3;
    display: block;
}

.bundle-options-container .bundle.items > li {
    margin-bottom: $space-base;
}

.bundle-options-container .box-tocart .actions {
    display: inline-block;
    margin-bottom: $space-base;
}

.bundle-options-container .nested .field.qty {
    margin-top: $form-field__vertical-indent;
}

.bundle-options-container .nested .field.qty .label {
    display: inline-block;
    margin: 0 0 $space-xs;
    font-weight: $form-field-label__font-weight;
}

.bundle-options-container p.required {
    color: $form-field-label-asterisk__color;
}

.bundle-options-container .product-options-bottom {
    clear: left;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .bundle-options-container .legend.title {
        margin-left: 0;
    }

    .bundle-options-container .bundle-options-wrapper,
    .bundle-options-container .product-options-wrapper {
        float: left;
        width: 57%;
    }

    .bundle-options-container .block-bundle-summary {
        float: right;
        position: relative;
        width: 40%;
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .bundle-options-container .bundle-options-wrapper,
        .bundle-options-container .block-bundle-summary {
            width: 48%;
        }
    }

}
