/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Text Align
// ==============================================

.a-center { text-align: center !important; }
.a-left   { text-align: left   !important; }
.a-right  { text-align: right  !important; }


// ==============================================
// Vertical Align
// ==============================================

.va-bottom      { vertical-align: bottom !important; }
.va-middle      { vertical-align: middle !important; }
.va-top         { vertical-align: top    !important; }
.va-text-top    { vertical-align: text-top    !important; }
