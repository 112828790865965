/**
* Baker Distributing
*/

// ==============================================
// Vars
// ==============================================

$panel-action-gap:        $space-l;
$panel-action-double-gap: $panel-action-gap + $panel-action-gap;


// ==============================================
// Panel
// ==============================================

.panel {
    position: relative;
    margin-bottom: $gap;
    border: 1px solid $c-border-light;
    background: $c-white;
}


// ==============================================
// Panel - Header
// ==============================================

.panel_header {
    display: flex;
    flex-wrap: nowrap;
    padding: $space-base $space-base 0;
}

@media (max-width: $bp-screen-s - 1px) {
    .panel_header {
        flex-wrap: wrap;
    }
}


// ==============================================
// Panel - Info
// ==============================================

.panel_info {
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .panel_info {
        order: 2;
        width: 100%;
    }

}


// ==============================================
// Panel - Action
// ==============================================

.panel_action {
    margin-bottom: $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .panel_action {
        order: 1;
        width: 100%;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .panel_action {
        flex: 0 0 360px;
    }

    .panel_action .action-group {
        float: right;
    }

}


// ==============================================
// Panel - Float
// ==============================================

.panel_float {
    margin: -$panel-action-gap 0 0 $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .panel_float {
        position: absolute;
        height: calc(100% + #{$panel-action-double-gap});
        top: -$panel-action-gap;
        right: 6px;
        margin: 0;
        z-index: $z-index-3;
    }

    .panel_float .float-button {
        position: sticky;
        top: calc(100% - 68px);
    }

}


// ==============================================
// Panel - Body
// ==============================================

.panel_body {
    padding: $space-base;
    height: 100%;
}

.panel_body > *:last-child {
    margin-bottom: 0;
}


// ==============================================
// Panel - Footer
// ==============================================

.panel_footer {
    padding: $space-base;
    border-top: 1px solid $c-border;
}
