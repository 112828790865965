// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */


//
//  Common
//  _____________________________________________


.navigation-wrapper {
    display: flex;
    justify-content: space-between;
    z-index: 1005;
}

.panel.header .links,
.panel.header .switcher {
    display: none;
}

.nav-sections {
    order: 1;
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size: 28px,
        $_icon-font-line-height: 40px,
        $_icon-font-color: $c-white,
        $_icon-font-color-hover: $c-white
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: $space-base;
    position: absolute;
    top: 5px;
    z-index: 15;
}

.nav-drawer_title {
    @include h2();
}

//
//  Customer Navigation
//  _____________________________________________

.customer-navigation {
    order: 2;
}

@media (max-width: $bp-screen-m - 1px) {
    .customer-navigation {
        margin-right: $layout__width-xs-indent;
    }
}

//
//  Dropdown arrow
//  _____________________________________________

.shop-wrapper:before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -8px;
    left: 129px;
}

.shop-wrapper:after {
    @include triangle($direction: up, $size: 6px, $color: $c-white);
    top: -6px;
    left: 131px;
}

.nav-mobile-account-links {
    display: none;
}


//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {

    .shop.dropdown {
        display: block;
    }

    .shop .shop-wrapper {
        display: block;
        position: static;
        border: none;
        box-shadow: none;
        width: 100%;
    }

    .shop-wrapper:before,
    .shop-wrapper:after {
        border: none;
    }

    .nav-sections {
        background: $navigation__background;
    }

    .navigation {
        padding: 5px 0px;
    }

    .header.links {
        padding-top: 5px;
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: calc(100% - #{$active-nav-indent});
    }

    .nav-sections .switcher {
        border-top: 1px solid $c-border;
        font-size: $font-size-l;
        margin: 0;
        padding: 0.8rem 3.5rem 0.8rem 2rem;
        @include bold;

        &:last-child {
            border-bottom: 1px solid $c-border;
        }
    }

    .nav-sections .switcher .label {
        display: block;
        margin-bottom: $space-xs;
    }

    .nav-sections .switcher-trigger strong {
        position: relative;
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 28px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );

        &::after {
            position: absolute;
            right: -40px;
            top: -16px;
        }
    }

    .nav-sections .switcher-trigger.active strong {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-up,
            $_icon-font-position: after
        );
    }

    .nav-sections .switcher-dropdown {
        padding: $space-base 0;
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;
    }

    .nav-before-open .page-wrapper {
        transition: left 0.3s;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: relative;
    }

    .nav-before-open body {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .nav-open .page-wrapper {
        left: calc(100% - #{$active-nav-indent});
    }

    .nav-open .nav-sections {
        box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        left: 0;
        z-index: 99;
    }

    .nav-open .nav-toggle {
        &::after {
            background: $c-overlay;
            content: '';
            display: block;
            height: 100%;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }

    .shop-items_all,
    .shop-items_subnav,
    .nav-sections-item-title {
        display: none;
    }

    .shop-items_title {
        position: relative;
        @include lib-icon-font(
            $_icon-font-content: $icon-pointer-right,
            $_icon-font-size: 24px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );

        &::after {
            position: absolute;
            right: 10px;
            top: 12px;
            color: $c-text-muted;
        }
    }

    .nav-sections-items {
        position: relative;
    }

    .nav-drawer {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: -100%;
        background: $navigation__background;
        transition: left .3s;
    }

    .nav-drawer .shop-items_subnav {
        display: block;
    }

    .nav-drawer_control {
        background: $c-secondary;
        color: $c-white;
        padding: $space-s $space-base;
        letter-spacing: 0.04em;
        cursor: pointer;
        @include lib-icon-font(
            $_icon-font-content: $icon-arrow-left-thin,
            $_icon-font-size: 24px,
            $_icon-font-display: block,
            $_icon-font-margin: -3px 10px 0 0
        );
    }

    .nav-drawer_title {
        margin: $navigation__indent-side;
    }

    .nav-sections-item-content,
    .nav-drawer_content {
        @include lib-main-navigation(
            $_nav-level0-item-padding: 8px 16px,
            $_nav-level0-item-margin: 0,
            $_nav-level0-item-color: $c-black
        );
    }

    .nav-sections-item-content a.shop-trigger,
    .nav-drawer_content a.shop-trigger {
        display: none;
    }

    .nav-mobile-account-links {
        display: block;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .nav-toggle {
        display: none;
    }

    .nav-sections {
        background: $navigation__background;
        flex-shrink: 0;
        flex-basis: auto;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > *:not(.menu-top)  {
        display: none;
    }

    .nav-sections-item-content > .navigation {
        display: block;
    }

    @include lib-main-navigation-desktop();

    .panel.header .links,
    .panel.header .switcher {
        display: inline-block;
    }

    .shop {
        height: 38px;
        padding-top: 10px;
    }

    .shop-wrapper .shop-items_subnav {
        columns: 2;
        column-rule: 1px solid $c-border;
        column-gap: 0px;
        margin-bottom: $space-base;
    }

    .shop-wrapper .shop-items_subnav li {
        break-inside: avoid;
        page-break-inside: avoid;

        &:hover {
            background: $blue-600;
        }
    }

    .shop-wrapper .shop-items_subnav li a {
        display: block;
        padding: $space-xs $space-base;
        color: $gray-900;
    }

    .shop-wrapper .shop-items_subnav li:hover a {
        color: $c-white;
        text-decoration: none;
    }

    .shop-nav {
        @include lib-data-tabs(
            $_title-selector : '.shop-items_title',
            $_content-selector : '.shop-items_content',
            $_tab-control-padding-right: 34px,
            $_tab-control-padding-left: 34px
        );
    }

    .shop-nav .shop-items_all {
        padding: $space-base;
        background: $gray-50;
        border-top: 1px solid $c-border;
    }

}