/**
* Baker Distributing
*/

// ==============================================
// Quickorder
// ==============================================


.quickorder-wrapper {
    display: flex;
    flex-wrap: nowrap
}

// ----------------------------------------------

@media (max-width: $bp-screen-m) {
    .quickorder-wrapper {
        flex-wrap: wrap;
    }
}



// ==============================================
// Quickorder Search
// ==============================================

.quickorder-search {
    flex-basis: 480px;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .quickorder-search {
        margin-right: $space-base;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .quickorder-search {
        flex-basis: 100%;
    }
}



// ==============================================
// Quickorder Result
// ==============================================

.quickorder-result {
    flex: 1 1 100%;
}


// ==============================================
// Quickorder Table
// ==============================================

.quickorder-table .col-image {
    width: 82px;
}

@media (max-width: $bp-table-linearize - 1px) {
    .quickorder-table .col-image {
        position: absolute
    }

    .quickorder-table .col-product,
    .quickorder-table .col-matches {
        margin-left: 60px;
    }

    .quickorder-table .col-action {
        float: right;
        padding-top: $space-xs;
    }

    .quickorder-table .col-qty {
         display: inline-flex;
    }
}


// ==============================================
// Quickorder Options selection
// ==============================================


@media (max-width: $bp-table-linearize - 1px) {

    .quickorder-selection_remove {
        float: right;
        margin-top: -34px;
    }

    .quickorder-selection tbody tr {
        position: relative;
    }

    .quickorder-selection .col-image {
        position: absolute;
    }

    .quickorder-selection .col-product {
        margin-left: 60px;
    }

    .quickorder-selection .col-action {
        margin-top: $space-base;
    }
}

// ==============================================
// Quickorder Warning
// ==============================================

.quickorder-warning_icon {
    font-size: 30px !important;
    line-height: inherit !important;
}