/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Width
// ==============================================

.width-auto { width: auto !important; }

.width-100 { width: 100% !important; }
.width-75  { width: 75%  !important; }
.width-50  { width: 50%  !important; }
.width-25  { width: 25%  !important; }
