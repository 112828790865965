/**
* Baker Distributing
*/


// ==============================================
// Menu Top
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .menu-top {
        position: static;
        padding: 12px 16px;
        background: $c-contrast-background;
        color: $c-white;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .menu-top {
        position: absolute;
        top: 98px;
        right: $layout-indent__width;
    }

    .menu-top .greet.welcome {
        display: none;
    }

}
