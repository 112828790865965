/**
* Baker Distributing
*/

// ==============================================
// Product Inventory - container
// ==============================================

.product-inventory {
    margin-top: 10px;
    font-weight: $font-weight-light;
}

.product-inventory_availability {
    font-weight: $font-weight-normal;
}


// ==============================================
// Inventory - stock
// ==============================================

.stock {
  margin-top: 2px;
}

.stock.available,
.stock.unavailable {
    font-weight: $font-weight-normal;
    vertical-align: top;
}

.stock.available .status {
    color: $c-inventory-available;
    @include lib-icon-font(
                    $icon-inventory-available,
            $_icon-font-color: $green-600,
            $_icon-font-size: 14px,
            $_icon-font-margin: -3px 2px 0 0
    )
}

.stock.out-of-stock .status,
.stock.unavailable .status {
    color: $c-inventory-unavailable;
    @include lib-icon-font(
                    $icon-inventory-unavailable,
            $_icon-font-color: $gray-700,
            $_icon-font-size: 14px,
            $_icon-font-margin: -3px 2px 0 0
    )
}

.stock span {
    font-weight: $font-weight-normal;
}


// ==============================================
// Inventory
// ==============================================

.inventory {
    display: inline-block;
    margin: $space-base 0;
}

.product-info-main .inventory {
    height: 119px;
}

.inventory_instore {
    background: $c-background;
    border: 1px solid $c-border-light;
    padding: $space-xxs $space-xs;
    margin-bottom: $space-xxs;
    font-weight: $font-weight-normal;
}

.inventory_stock.available {
     color: $c-inventory-available;
}

.inventory_stock.unavailable,
.inventory_stock.out-of-stock{
    color: $c-inventory-unavailable;
}

.inventory_store-name {
    @include lib-icon-font(
                    $icon-location,
            $_icon-font-color: $gray-700,
            $_icon-font-size: 16px,
            $_icon-font-margin: -2px 2px 0 -4px
    )
}

.inventory_nearby,
.inventory_all {
   font-size: $font-size-s;
    font-weight: $font-weight-light;
}

.inventory_all {
    margin-bottom: $space-xxs;
}