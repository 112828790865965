/**
* Baker Distributing
*/

// ==============================================
// Page Layout - 1 column
// ==============================================

.page-layout-1column .columns .column.main {
    width: 100%;
}


// ==============================================
// Content Wrapper on CMS Page Layout - 1 column
// ==============================================


.page-layout-1column .content-wrapper {
    margin-right: -$layout-indent__width;
    margin-left: -$layout-indent__width;
}

@media (max-width: $bp-screen-m - 1px) {
    .page-layout-1column .content-wrapper {
        margin-right: -$layout__width-xs-indent;
        margin-left: -$layout__width-xs-indent;
    }
}
