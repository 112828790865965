/**
* Baker Distributing
*/


// **********************************************
// [todo] further consolidate the "column" and "sidebar" versions of
// "block add by SKU". There's probably no need for isolated UIs.
// **********************************************


// ==============================================
// Block Add By SKU
// ==============================================

.block-addbysku .fieldset {
    margin: 0;
}

.block-addbysku .fields {
    position: relative;
}

.block-addbysku .field {
    display: inline-block;
    margin-bottom: $space-base;
    vertical-align: top;
}

.block-addbysku .actions-toolbar {
    position: absolute;
    right: 0;
    top: $space-xxs;
}

.block-addbysku .field.qty .qty + .mage-error {
    width: 80px;
}


// ==============================================
// Column > Block Add By SKU
// ==============================================

.column .block-addbysku .sku {
    margin-right: -155px;
    padding-right: 160px;
    width: 100%;
}

.column .block-addbysku .skus > .label {
    margin-top: $space-base;
}

.column .block-addbysku .block-content {
    @include lib-clearfix;
}

.column .block-addbysku .block-content .box {
    @extend .abs-blocks-2columns;
}

.column .block-addbysku .block-content .actions-toolbar {
    clear: both;

    @include lib-actions-toolbar(
        $_actions-toolbar-actions-position : left,
        $_actions-toolbar-margin           : 0 0 $space-l
    );
    @include lib-actions-toolbar-clear-floats();
}

.column .block-addbysku .block-content .actions-toolbar > .secondary {
    text-align: left;
}

.column .block-addbysku .reset {
    margin: $space-base 0;
}

.column .block-addbysku .note {
    display: block;
    margin: 0 0 $space-l;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .column .block-addbysku .sku {
        margin-right: -85px;
        padding-right: 90px;
    }

    .column .block-addbysku .action.remove {
        @include lib-button-icon(
            $_icon-font-content   : $icon-remove,
            $_icon-font-text-hide : true,
            $_icon-font-size      : 28px,
            $_icon-font-margin    : 1px 0 0
        );
        @include lib-button-reset();
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .column .block-addbysku .action.remove {
        @include lib-button-reset();
        margin-top: 2px;
    }

}


// ==============================================
// Sidebar > Block Add By SKU
// ==============================================

.sidebar .block-addbysku .field.sku {
    margin-right: -85px;
    padding-right: 90px;
    width: 100%;
}

.sidebar .block-addbysku .form-addbysku .actions-toolbar {
    margin-bottom: $space-l;
}

.sidebar .block-addbysku .form-addbysku .actions-toolbar .primary {
    text-align: left;
}

.sidebar .block-addbysku .form-addbysku .actions-toolbar .primary .action {
    width: auto;
}

.sidebar .block-addbysku .form-addbysku .actions-toolbar .secondary {
    float: none;
    text-align: left;
}

.sidebar .block-addbysku .action.add {
    @include lib-button-icon(
        $_icon-font-content  : $icon-expand,
        $_icon-font-text-hide: true,
        $_icon-font-size     : 31px
    );
    line-height: normal;
    padding: $space-xxxs 0;
    width: auto;
}

.sidebar .block-addbysku .action.links {
    display: inline-block;
    margin: $space-l 0 0;
}

.sidebar .block-addbysku .action.reset {
    display: block;
    margin: $space-base 0;
}

.sidebar .block-addbysku .action.remove {
    @include lib-button-icon(
        $_icon-font-content  : $icon-remove,
        $_icon-font-text-hide: true,
        $_icon-font-size     : 28px
    );
    @include lib-button-reset();
}
