/**
* Baker Distributing
*/


// ==============================================
// Control
// ==============================================

.control {
    position: relative;
    margin-top: 18px; // Offset for animated label. Update "field-row" when changing.
}

label ~ .control {
    margin-top: 0;
}

.control label {
    position: absolute;
    left: 0;
    color: $c-label;
    font-weight: normal;
    letter-spacing: $letter-spacing;
    line-height: 10px;
    transition: all ease 0.2s;
    pointer-events: none;
    white-space: nowrap;
}
