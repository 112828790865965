/**
* Baker Distributing
*/


// ==============================================
// Vars
// ==============================================

$toolbar-mode-icon-font-size: 24px;
$toolbar-element-background: $c-lightestgray;


// ==============================================
// Toolbar Wrap
// ==============================================

//.toolbar-wrap {}
//.toolbar-wrap--top {}

.toolbar-wrap--bottom {
    margin-bottom: $space-l;
}

.toolbar-wrap--bottom .toolbar::after {
    @include clearfix;
}


// ==============================================
// Toolbar
// ==============================================

//.toolbar {}


// ==============================================
// Toolbar Products
// ==============================================

.toolbar-products {
    overflow: hidden;
}

.toolbar-wrap--bottom .toolbar-products {
    margin-bottom: $space-l;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .toolbar-wrap--bottom .toolbar-products {
        margin-top: 10px;
    }

}


// ==============================================
// Filters Mobile Toggle
// ==============================================

.filters-mobile-toggle {
    float: right;
}

.toolbar-wrap--bottom .filters-mobile-toggle {
    display: none;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .filters-mobile-toggle {
        display: none;
    }

}


// ==============================================
// Limiter
// ==============================================

.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}

.limiter-label {
    font-weight: 400;
}

.toolbar .toolbar-limiter {
    display: none;
}

.account .toolbar-wrap--bottom .toolbar-limiter {
    margin-bottom: $space-base;
}

.toolbar-limiter .control {
    display: inline-block;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .toolbar-limiter {
        float: right;
    }

    .toolbar-wrap--bottom .toolbar-limiter {
        display: block;
    }

}


// ==============================================
// Pages
// ==============================================

.toolbar-wrap--top .pages {
    display: none;
}

.toolbar-wrap--bottom .pages {
    display: block;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .toolbar-wrap--bottom .pages {
        float: left;
    }

}


// ==============================================
// Toolbar Amount
// ==============================================

.toolbar-amount {
    display: none;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .toolbar-amount {
        position: absolute;
        margin-top: -45px;
        width: 100%;
        text-align: center;
        padding-bottom: 8px;
        background: $gray-50;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .toolbar-amount {
        float: right;
        margin-left: 50px;
    }

}


// ==============================================
// Toolbar Sorter
// ==============================================

.toolbar-sorter {
    float: left;
}

.toolbar-wrap--bottom .toolbar-sorter {
    display: none;
}

.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
}

.sorter-action {
    padding: 7px 10px;
    border: 1px solid $gray-50;
    border-radius: $radius-base;
    vertical-align: middle;
    transition: $transition-all;

    &:hover {
        text-decoration: none;
        background: $gray-50;
        transition: $transition-all;
    }

    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size        : 18px,
        $_icon-font-color       : $c-primary,
        $_icon-font-color-hover : $c-primary,
        $_icon-font-position    : after,
        $_icon-font-text-hide   : true
    );

    &::after {
        margin-top: -4px;
    }
}

.sort-desc {
    &::after {
        content: $icon-arrow-down;
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-xs - 1px) {

    .sorter-label {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .toolbar-sorter {
        float: right;
        margin-left: 50px;
    }

}
