/**
* Baker Distributing
*/


// ==============================================
// Input Text
// ==============================================

.input-text {
    @include text-field(
        $input-border: none,
        $input-padding: 0
    );

    @include label-color (
        $label: $c-label,
        $label-focus: $c-label-focus
    );

    @include label-position(
        $label-top: 14px,
        $label-top-focus: -12px,
        $label-left: 0px,
        $label-font-size: $form-elem-font-size,
        $label-font-size-focus: 13px
    );

    &[type="date"]::-webkit-calendar-picker-indicator {
        color: $c-input-border-bottom;
    }

    @include input-date(
        $placeholder: transparent,
        $placeholder-focus: $c-text
    );
}


// ==============================================
// Modifier: Size
// ==============================================

.input-text--s {
    max-width: $form-elem-width-s;
}

.input-text--xs {
    max-width: $form-elem-width-xs;
}

.input-text--xxs {
    max-width: $form-elem-width-xxs;
}


// ==============================================
// Context: .form--contrast
// ==============================================

.form--contrast .input-text {
    color: $c-contrast-input-text;
    border-bottom-color: $c-contrast-input-border-bottom;

    &:focus {
        border-bottom-color: $c-contrast-input-border-focus;
    }

    @include label-color (
        $label: $c-contrast-label,
        $label-focus: $c-contrast-label-focus
    );

    @include input-date(
        $placeholder: transparent,
        $placeholder-focus: $c-contrast-label
    );

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: $c-contrast-input-text-fill;
        -webkit-box-shadow: 0 0 0px 1000px $gray-800 inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}
