/**
* Baker Distributing
*/


// ==============================================
// Page Footer
// ==============================================

.page-footer {
    margin-top: auto;
}


// ==============================================
// Footer Top
// ==============================================

.footer-top {
    position: relative;
    padding:  10px 90px 10px 20px;
    background: $gray-100;
    font-size: $font-size-l;
    border-top: 1px solid $c-border;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .footer-contact_separator {
        display: none;
    }

    .footer-contact_item,
    .footer-contact_label {
        display: block;
    }

    .footer-contact_label {
        font-size: $font-size-xs;
    }

}

// ==============================================
// Back Top
// ==============================================

.back-top {
    display: inline-block;
    position: absolute;
    right: $space-l;
    bottom: 0;
    width: 48px;
    height: 48px;
    margin-bottom: (50px / -2);
    background: $c-white;
    border-radius: 50px;
    box-shadow: 0 1px 2px black(0.15);
    text-decoration: none;
    cursor: pointer;
    transition: $transition-all;
    z-index: $z-back-top;

    &:hover {
        transform: scale(1.1);
    }

    @include lib-icon-font(
        $_icon-font-content: $icon-up,
        $_icon-font-color:   $gray-700
    );

    &::before {
        padding: 15px 13px;
    }
}


// ==============================================
// Footer Content
// ==============================================

// [todo] remove chaining
.footer.content {
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid $c-border;
    background: #f9f9f9;
}


// ==============================================
// Footer Nav
// ==============================================

.footer-nav {
    max-width: $bp-screen-l;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    border-bottom: 1px solid $c-border;
}

.footer-nav_item {
    flex: 0 0 auto;
    margin: $gap auto;
}

.footer-nav_link {
    display: block;
    margin-bottom: 0.5em;
}

a.footer-nav_link {
    color: $c-text;
}

.footer-nav_heading a {
    color: $c-text;
    font-weight: $font-weight-bold;
}

.social-link {
    margin-left: 20px;
    width: 130px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.social-link_item .social-link_background {
    fill-rule: evenodd;
    fill: $gray-700;
    transition: $transition-all;
}

.social-link_item:hover .social-link_background {
    fill: $gray-900;
    transition: $transition-all;
}

.social-link_icon {
    fill: $c-white;
    fill-rule: evenodd;
}

.app-link {
    width: 120px;
    margin-left: 20px;
    margin-top: $gap;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.app-link_item {
    width: 120px;
    height: 40px;
    margin-bottom: $space-l;
}

.app-link_item .app-link_color {
    fill: $gray-600;
    fill-rule: evenodd;
    transition: $transition-all;
}

.app-link_item:hover .app-link_color {
    fill: $gray-900;
    transition: $transition-all;
}


// ----------------------------------------------


@media (max-width: $bp-screen-m - 1px) {
    .footer-nav_item--social {
        flex: 1 0 100%;
        width: 100%;
    }

    .social-link,
    .app-link {
        margin: $gap auto 0;
    }

    .app-link {
        width: 300px;
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .footer-nav_item {
        flex: 0 0 200px;
        margin: $space-base auto;
    }

    .footer-nav_item--social {
        flex: 1 0 100%;
        width: 100%;
    }
}


// ==============================================
// Footer Logo
// ==============================================

.footer-logo {
    padding: $gap 0;
}

.footer-logo_baker-fcs,
.footer-logo_baker,
.footer-logo_ice-design {
    margin: 0 auto;
    height: auto;
}

.footer-logo_baker {
    width: 150px;
}

.footer-logo_ice-design,
.footer-logo_baker-fcs {
    width: 200px;
}
