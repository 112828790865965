/**
* Baker Distributing
*/


// ==============================================
// Collapsible
// ==============================================

.collapsible_title {
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing;
    color: $c-text;
    text-decoration: none;
    word-break: break-word;
    cursor: pointer;
    padding: $space-s 0 $space-s $space-xs;
    margin: 2px -8px 2px -8px;
    border-radius: $radius-base;
    transition: $transition-all;

    @include lib-icon-font(
            $_icon-font-content: $icon-next,
            $_icon-font-size: 16px,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-margin: -3px 0 0 0
    );
}

.collapsible_title:hover {
    background: $blue-50;
    color: $c-text;
    text-decoration: none;
}

.collapsible_title--opened {
    @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 16px,
            $_icon-font-position: before,
            $_icon-font-display: block
    );
}

.collapsible_content {
    margin-left: $space-base;
}