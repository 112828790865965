/**
* Baker Distributing
*/

// ==============================================
// Branch Selector
// ==============================================

.branchSelector-wrapper {
    padding: 10px 0;
    width: 100%;
    margin-top: 10px;
}


// ==============================================
// Branch Selector - Results
// ==============================================

.branchSelector-results {
    padding-left: 20px;
    list-style: none;
    counter-reset: item;
}

// ----------------------------------------------

.branchSelector-results .branch-distance-location .directions,
.branchSelector-results .actions-primary .secondary {
    display: none;
}

@media (min-width: $bp-screen-m) {

    .branchSelector-results {
        position: relative;
        overflow-y: scroll;
        max-height: 65vh;
        padding: 20px 20px 0 20px;
    }

}


// ==============================================
// Branch Selector - Item
// ==============================================

.branchSelector-item {
    padding: 0 0 15px 12px;
    counter-increment: item;
    margin-bottom: 25px;
    position: relative;

    &:last-child {
        border-bottom: 0;
    }

    &::before {
        content: counter(item);
        margin-right: 10px;
        color: $gray-600;
        width: 1.2em;
        float: left;
        margin-left: -30px;
    }

    &::after {
        content: '';
        background: $gray-100;
        height: 1px;
        width: calc(100% + 20px) ;
        display: block;
        position: absolute;
        bottom: 0;
        left: -20px;
    }
}

.branchSelector-item .actions-primary {
    margin: 10px 0;
}

// [todo] - Should relocate to "store-hour" component
.branchSelector-item .storeItem-hour {
    padding: 10px 0;
}

// [todo] - Should relocate to "store-hour" component
.branchSelector-item .storeItem-hour .open {
    color: $green-700;
}

// [todo] - Should relocate to "store-hour" component
.branchSelector-item .storeItem-hour .closed {
    color: $c-brand;
}

.branchSelector-item .storeItem-hour {
    @include lib-clearfix;
    display: inline-block;
    position: relative;

    &.active {
        overflow: visible;

        .storeHour-detail {
            display: block;
        }
    }
}

.branchSelector-item .storeItem-hour .storeHour-detail {
    display: none;
    position: static;
    border: 1px solid $c-border;
}

// [todo] - Should relocate to "store-hour" component
.branchSelector-item .storeHour-info {
    cursor: pointer;
    @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 24px,
            $_icon-font-line-height: 22px,
            $_icon-font-color: $green-700,
            $_icon-font-color-hover: $green-700,
            $_icon-font-color-active: $green-700,
            $_icon-font-position: 'after'
    );

    &.active {
        @include lib-icon-font(
                $_icon-font-content: $icon-up,
                $_icon-font-size: 24px,
                $_icon-font-line-height: 22px,
                $_icon-font-color: $green-500,
                $_icon-font-color-hover: $green-500,
                $_icon-font-color-active: $green-500,
                $_icon-font-position: 'after'
        );
    }
}

.branchSelector-item .storeHour-info.closed::after,
.branchSelector-item .storeHour-info.closed.active,
.branchSelector-item .storeHour-info.closed:hover::after {
    color: $c-brand;
}


// ==============================================
// Branch Selector - Detail
// ==============================================

.branchSelector-detail {
    position: absolute;
    right: 10%;
    top: 30%;
    background: $c-white;
    padding: 30px;
    border: 1px solid $gray-200;
    box-shadow: 0 3px 3px black(0.15);
    max-width: 280px;

    &::before {
        content: '';
        position: absolute;
        background: $c-white;
        width: 18px;
        height: 18px;
        top: 0;
        bottom: 0;
        left: -10px;
        margin: auto;
        border: 1px solid $gray-200;
        border-width: 1px 1px 0 0;
        $_shadow: 2px -2px 2px -1px black(0.1);
        box-shadow: $_shadow;
        transform: rotate(-135deg);
        z-index: 100;
    }
}

// ==============================================
// Branch Name
// ==============================================


.branch-name {
    cursor: pointer;
}
