/**
* Baker Distributing
*/

// ==============================================
// Vars
// ==============================================

$float-button-size: 48px !default;
$float-button-icon-margin: -2px 4px 0px -4px !default;


// ==============================================
// Float Button
// ==============================================

.float-button {
    height: $float-button-size;
    width: auto;
    border: 0;
    border-radius: $float-button-size;
    box-shadow: $button-box-shadow;
    cursor: pointer;
    display: inline-block;
    font-weight: $font-weight-normal;
    padding: 14px 16px;
    font-size: 14px;
    letter-spacing: $letter-spacing;
    vertical-align: middle;
    text-align: center;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    will-change: background, box-shadow;
    transition: $transition-all;

    @include button-style(
            $background         : $c-primary,
            $background--hover  : $blue-700,
            $background--active : $blue-700,
            $color              : $c-white
    );
}

.float-button:hover,
.float-button:focus {
    text-decoration: none;
    box-shadow: $button-box-shadow-hover;
}

// ==============================================
// Modifier: secondary
// ==============================================
.float-button--secondary {
    @include button-style(
            $background         : $c-white,
            $background--hover  : $c-lightestgray,
            $background--active : $c-lightestgray,
            $color              : $c-icon-base
    );
}

// ==============================================
// Modifier: add
// ==============================================

.float-button--add {
    @include lib-icon-font(
            $_icon-font-content: $icon-add,
            $_icon-font-text-hide: false,
            $_icon-font-margin: $float-button-icon-margin
    );
}

// ==============================================
// Modifier: edit
// ==============================================

.float-button--edit {
    @include lib-icon-font(
            $_icon-font-content: $icon-edit,
            $_icon-font-text-hide: false,
            $_icon-font-margin: $float-button-icon-margin
    );
}

// ==============================================
// Modifier: prev
// ==============================================

.float-button--prev {
    @include lib-icon-font(
            $_icon-font-content: $icon-pointer-left,
            $_icon-font-text-hide: false,
            $_icon-font-margin: $float-button-icon-margin
    );
}

.float-button--prev span {
    display: none;
}

// ==============================================
// Modifier: next
// ==============================================

.float-button--next {
    @include lib-icon-font(
            $_icon-font-content: $icon-pointer-right,
            $_icon-font-text-hide: false,
            $_icon-font-margin: $float-button-icon-margin
    );
}
.float-button--next span {
    display: none;
}

// ----------------------------------------------

@media (max-width: $bp-screen-l - 1px) {
    .float-button--add,
    .float-button--edit {
        width: $float-button-size;
    }

    .float-button--add span,
    .float-button--edit span {
        display: none;
    }
}

// ----------------------------------------------
// Disabled

.float-button:disabled,
.float-button.disabled {
    @include button-style (
            $background         : $gray-100,
            $background--hover  : $gray-100,
            $background--active : $gray-100,
            $color              : $gray-300
    );
    box-shadow: none;
    cursor: not-allowed;
}

// ==============================================
// Modifier: Mini
// ==============================================

.float-button--mini {
    height: 40px;
    padding: $space-xs;
}

.float-button--mini::before {
    width: 24px;
    margin: 0;
}