/**
* Baker Distributing
*/


// ==============================================
// Resource Link
// ==============================================

.resource-link-wrapper {
    max-width: 250px;
}

.resource-link {
    background-color: $c-lightestblue;
    color: $c-text;
    width: 100%;
    padding: $space-xs $space-base;
    font-weight: $font-weight-semibold;
    display: block;
}

.resource-link--parts {
    padding-left: 54px;
    position: relative;
    transition: $transition-all;

    &:before {
        content: '';
        position: absolute;
        left: $space-base;
        top: 6px;
        width: 24px;
        height: 24px;
        display: block;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2Ny41IDY1LjciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDY3LjUgNjUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiCgkgY2xhc3M9InN2ZyBzdmctaWNvbiBzdmctaWNvbi0tYmFzZSBtLXIteHMiPgoJPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCQkuc3Qwe2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO2ZpbGw6IzAwMH0KCTwvc3R5bGU+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTkuOCwzMS41Yy0yLjQtMS40LTUuMS0yLjEtNy44LTIuMWMtMi4yLDAtNC41LDAuNS02LjQsMS40Yy0xLjEtMi41LTMtNC41LTUuMy01LjdjMC4xLTQuMSwyLjMtNy44LDUuOS05LjgKCQljMS4xLTAuNiwyLjMtMS4xLDMuNS0xLjNjMi4xLTAuNSwzLjgtMi4xLDQuMi00LjJjMC40LTIuMS0wLjUtNC4yLTIuNC01LjNDNDcuMiwxLjcsNDEuOCwwLjIsMzYsMGMtNC41LTAuMi04LjYsMS41LTExLjgsNC41CgkJYy0zLjEsMy00LjksNi45LTQuOSwxMS4yYzAsNS4yLDIuNiw5LjksNi42LDEyLjhjLTEuNSwxLjktMi4zLDQuMy0yLjMsNi44YzAsMC4yLDAsMC40LDAsMC42Yy0xLjcsMC45LTMuNywxLjQtNS42LDEuNAoJCWMtMiwwLTQuMS0wLjUtNS45LTEuNmMtMS4xLTAuNi0yLTEuNC0yLjktMi40Yy0xLTEuMi0yLjQtMS44LTMuOS0xLjhjLTIuOCwwLTUuMiwyLjItNS4zLDVjLTAuMiw0LjksMS4yLDEwLjQsMy45LDE1LjUKCQljMi45LDUuMyw4LjQsOC43LDE0LjMsOC43YzIuNywwLDUuMy0wLjcsNy42LTJjNC41LTIuNiw3LjMtNy4yLDcuOC0xMi4xYzAuNSwwLjEsMC45LDAuMSwxLjQsMC4xYzIuMiwwLDQuMy0wLjYsNi4xLTEuOAoJCWMzLjUsMi4xLDUuNyw1LjksNS43LDEwYzAsMS4zLTAuMiwyLjUtMC41LDMuN2MtMC42LDEuNi0wLjMsMy40LDAuNyw0LjhjMSwxLjQsMi42LDIuMiw0LjIsMi4yYzAuOSwwLDEuNi0wLjIsMi40LTAuNgoJCWM0LjQtMi4zLDguNC02LjIsMTEuNS0xMS4xYzIuNC0zLjgsMy4xLTguNSwyLTEyLjdDNjYsMzcsNjMuNCwzMy42LDU5LjgsMzEuNUw1OS44LDMxLjV6IE0zNSw0Mi4xYy0zLjcsMC02LjgtMy4xLTYuOC02LjgKCQljMC0zLjcsMy4xLTYuOCw2LjgtNi44YzMuNywwLDYuOCwzLjEsNi44LDYuOEM0MS44LDM5LDM4LjcsNDIuMSwzNSw0Mi4xTDM1LDQyLjF6Ii8+Cjwvc3ZnPgo=");
        background-repeat: no-repeat;
        background-position: left center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-clip: padding-box;
        box-sizing: content-box;
        outline: 0;
    }
}

.resource-link:hover {
    background: $blue-100;
    color: $c-text;
    text-decoration: none;

    &:before {
        animation: rotate-left 0.8s cubic-bezier(0.175, 0.785, 0.420, 1.125) both;
    }
}
