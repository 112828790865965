// ==============================================
// Stocking List Listing
// ==============================================

.stocking-list-listing .cell-label-line-description {
    color: $c-text-muted;
}


// ==============================================
// Stocking List View
// ==============================================

.stocking-list-description {
    word-wrap: break-word;
}

@media all and (max-width: 768px) {

    .stocking-list-tabs .tabs_nav {
        flex-direction: column;
    }

}

// ==============================================
// Stocking List Settings
// ==============================================

.form-stocking-list-settings .field--inline .field-item {
    white-space: nowrap;
}

.form-stocking-list-settings textarea.input-text {
    max-height: 200px;
    resize: vertical;
}

.form-stocking-list-settings textarea.input-text--s {
    max-width: 28rem;
}

.form-stocking-list-settings textarea.input-text--s + label {
    max-width: calc(28rem - 20px);
}

.form-stocking-list-settings label.error[generated="true"] {
    position: relative;
    top: 0;
    color: $c-red;
    font-size: 13px;
}

.form-stocking-list-settings .input-invitee-email-wrap {
    max-width: 440px;
}

.form-stocking-list-settings .select-supplier-wrap {
    max-width: 400px;
}

.form-stocking-list-settings select option[disabled] {
    display: none;
}

@media all and (min-width: 768px) {
    .stocking-list-settings_supplier .select-row .field {
        width: 50%;
    }
}

@media all and (max-width: 768px) {

    .stocking-list-settings_info .field--inline,
    .stocking-list-settings_order .field--inline,
    .stocking-list-settings_supplier .field-row {
        display: block;
    }

    .stocking-list-settings_info .field-item,
    .stocking-list-settings_order .field-item {
        margin-left: 0;
    }

}

// ==============================================
// Stocking List Assortment
// ==============================================

.stocking-grid .table td {
    vertical-align: top;
}

.stocking-grid tr.headings th:first-child,
.stocking-grid .col.select {
    padding-left: 0;
    padding-right: 0;
}

.stocking-grid.requisition-grid .product-item-image {
    width: 60px;

    img {
        margin-left: auto;
        margin-right: auto;
        max-height: 60px;
    }
}

.stocking-grid .product-item-name,
.stocking-grid .product-attribute,
.stocking-grid .product-price {
    margin-left: 75px;
}

.stocking-grid .col.product {
    padding-left: 0;
}

.stocking-grid .col.actions {
    padding-left: 0;
    padding-right: 0;
}

.stocking-list-item-attribute_value {
    color: $c-text-muted;
    font-size: $font-size-s;
}

.stocking-list-item-attribute_value.user-product-number {
    min-width: 140px;
}

.stocking-grid tbody .col.qty {
    text-align: center;

    .item-min-qty,
    .item-max-qty {
        display: block;
        padding: 8px;
        background-color: #efefef;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
}

.stocking-list-items_user-notes table {
    th, td {
        padding-top: 0;
        padding-left: 0;
        border: none;
    }
}

#stocking-list-adding-paste textarea {
    min-width: 100%;
    max-height: 260px;
}

@media all and (max-width: 699px) {

    .stocking-manage-column .field--inline {
        display: block;

        .field-item {
            margin-left: 0;
        }
    }

    .stocking-grid .table--linearize tbody tr {
        clear: both;
    }

    .stocking-grid tbody .col.product {
        margin-left: 0;
    }
    #form-stocking-list-barcode tbody .col.product {
        margin-left: 30px;
    }

    .stocking-grid tbody .col.qty {
        float: none;
        margin-right: 0;
    }

    .stocking-grid .product-item-name,
    .stocking-grid .product-attribute,
    .stocking-grid .product-price {
        margin-left: 0;
    }

}

@media all and (min-width: 768px) {

    #stocking-manage-adding .panel_header .stocking-manage-column {
        &:first-child {
            width: 30%;
        }

        &:last-child {
            width: 70%;
        }
    }

    #stocking-manage-adding .panel_body {
        display: flex;

        &:after {
            content: "";
            clear: both;
            display: table;
        }
    }

    #stocking-manage-adding .panel_body .stocking-manage-column {
        float: left;

        &:first-child {
            width: 30%;
        }

        &:last-child {
            width: 70%;
        }
    }

    #stocking-list-adding-paste {
        .stocking-list-paste_column-1,
        .stocking-list-paste_column-2 {
            float: left;
            padding-right: 20px;
            width: 50%;
        }
    }
}

.form-stocking-list-add-item {
    margin-bottom: 0;
}

.form-stocking-list-add-item .product-image {
    width: 60px;
    height: 60px;
    text-align: center;

    img {
        margin-left: auto;
        margin-right: auto;
        max-height: 100%;
    }
}

.stocking-item-popup.modal .modal-content {
    padding-top: 0;
    padding-bottom: 0;
}

.modal-stocking-quick-add-popup.modal--scroll .modal-inner-wrap {
    height: auto;
    max-height: 80%;
}

// Stocking List Quick Add
.stocking-list-quick-add-submit-form  {
    .stocking-list-quick-add-item,
    .hr--thick {
        float: left;
        width: 100%;
    }
}

.stocking-list-quick-add-submit-form .stocking-list-quick-add-item .product-image {
    max-width: 60px;
}

.stocking-list-quick-add-item_col-1 {
    flex: 1;
}


// ==============================================
// Stocking List Barcodes
// ==============================================

.barcodes-select-items-modified-since .control {
    margin-top: 0.8rem;
}

input[data-action="barcodes-select-modified-after"] {
    width: 234px;
}


// ==============================================
// Stocking List Order Management
// ==============================================

.stocking_list-stocking-view .notify {
    z-index: $z-modal + 1;
}

.stocking-list-order-actions {
    .simple-loading {
        width: 24px;
        height: 24px;
    }
}

.stocking-list-pending-orders-table .field-po-number {
    min-width: 120px;
}

.stocking-list-in-progress-orders-table .td-po-number {
    word-break: break-word;
}

.stocking-list-order-details-items {

    .product-image {
        max-width: 75px;
    }

    .product-name {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.5;
    }

    .col.qty {
        text-align: right;
    }

    .col.subtotal .price {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
    }
}

.stocking-list-order-details-modal .totals-table {
    float: right;
    margin-bottom: $space-base;

    td {
        padding-right: 8px;
        min-width: 75px;
    }
}

.inventory-wrap {
    .inventory-instore,
    .inventory-nearby,
    .inventory-all {
        font-size: 13px;
        font-weight: 300;
    }

    .stock.available {
        color: $c-green;
    }
    .stock.unavailable,
    .stock.out-of-stock {
        color: $c-red;
    }
}
