/**
* Baker Distributing
*/


// ==============================================
// Sidebar Main
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .page-with-filter .columns .sidebar-main {
        order: 0;
    }

    .sidebar-main .filter hr {
        display: none;
    }

}

// ==============================================
// Modes
// ==============================================

.view-mode {
    display: none;
}

@media (min-width: $bp-screen-m) {

    .view-mode {
        display: block;
        width: 100%;
    }

    .modes {
        margin: $space-xs 0;
    }

    .modes-mode {
        padding: 7px 12px;
        border: 1px solid $gray-50;
        color: $c-text-muted-dark;
        font-weight: $font-weight-normal;
        line-height: 1;
        text-align: center;
        transition: $transition-all;

        @include lib-icon-font(
                        $icon-grid,
                $_icon-font-size: $toolbar-mode-icon-font-size,
                $_icon-font-position: after,
                $_icon-font-color: $gray-600
        );

        &:not(.active),
        &:not(.active)::after {
            color: $c-link;

            &:hover,
            &:hover::after {
                color: $c-link;
                background: $gray-50;
                text-decoration: none;
                transition: $transition-all;
            }
        }

        &.active,
        &.active::after {
            background: $gray-50;
            color: $c-text-muted-dark;
        }

        &::before {
            content: none;
        }
    }

    .mode-list {
        @include lib-icon-font(
                        $icon-list,
                $_icon-font-size: $toolbar-mode-icon-font-size,
                $_icon-font-position: after,
                $_icon-font-color: $c-primary
        );
    }

}


// ==============================================
// Block . Filter
// ==============================================

//.block.filter {}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .block.filter .categories-content {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .block.filter {
        margin-bottom: $space-xxl;
    }

}


// ==============================================
// Block Subtitle
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .block.filter .block-subtitle {
        display: none;
    }

}


// ==============================================
// Filter Title
// ==============================================

.filter-title {
    margin-bottom: $space-l;
}

.filter-title strong {
    font-size: $font-size-xl;
}


// ==============================================
// Filter Current
// ==============================================

.filter-current .items {
    display: flex;
    flex-wrap: wrap;
}

.filter-current .item {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 5px 5px 0;
    padding: 5px 5px 5px 25px;
    background: $blue-50;
    border-radius: $radius-base;
    position: relative;
    font-size: $font-size-s;
}

@media (max-width: $bp-screen-m - 1px) {
    .filter-current .item {
        padding: 12px 12px 12px 35px;
    }
}

.filter-current .item .action.remove {
    font-size: 0px;

    @include lib-icon-font(
        $icon-remove,
        $_icon-font-size           : 18px,
        $_icon-font-line-height    : 18px
    );

    &::before {
        position: absolute;
        left: 2px;
        color: $c-text;
    }

}


@media (max-width: $bp-screen-m - 1px) {
    .filter-current .item .action.remove:before {
        left: 8px;
    }
}

.filter-actions {
    margin-bottom: $space-xxl;
}


// ==============================================
// Filter Options
// ==============================================

.filter-options {
    margin: 0;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .block.filter {
        display: none;
    }

    .filter-mobile_options .filter-options {
        display: block;
    }
}


// ==============================================
// Filter Options Item
// ==============================================

.count {
    color: $c-text-muted-dark;
    font-weight: $font-weight-light;

    &::before {
        content: '(';
    }

    &::after {
        content: ')';
    }
}

// ==============================================
// Filter Options Content
// ==============================================


.inventory-content .filter-count-label,
.filter-options .filter-count-label {
    @include lib-visually-hidden;
}

.filter-options .item {
    margin-bottom: 3px;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {


    .filter-options .item {
        padding: 12px 0;
    }

    .filters-mobile,
    .filters-mobile-toggle {
        display: block;
    }

    .filters-mobile-toggle {
        position: absolute;
        margin-top: -60px;
        right: $space-base;
    }

    .products.wrapper ~ .toolbar .filters-mobile-toggle {
        display: none;
    }

    .filters-mobile .filter-content .filter-subtitle {
        display: none;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .filters-mobile,
    .filters-mobile-toggle {
        display: none;
    }

}
