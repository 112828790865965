/**
* Baker Distributing
*/


// ==============================================
// Page Title Wrapper
// ==============================================

.page-products .page-wrapper .page-title {
    padding-bottom: 10px;
    border-bottom: none;
    margin-bottom: 0;
    padding-right: 115px;
}

.page-products .page-title-wrapper {
    border-bottom: 1px solid $c-border;
}


//todo - use in other pages where the background will be gray below the title.
.page-products .page-main {
    padding-top: 0.6em;
    background: $c-background;
}

// ==============================================
// Page Toolbar
// ==============================================

@media (min-width: $bp-screen-m) {
    .page-products .toolbar-wrap--top .toolbar-limiter {
        display: block;
    }
}

// ==============================================
// Product Item Info
// ==============================================

// [todo] refactor.
// Only need this because "_product-item.scss" sets a hard width value.
// This should probably not be defined and let the container determine the width.
// Search for "product-item-info" project-wide to see usage.
.page-products .product-item-info {
    width: 100%;
}


// ==============================================
// Catalog Page - Sidebar
// ==============================================

// ==============================================
// Page Title Wrapper
// ==============================================
.page-products .page-title-wrapper > h1,
.search-auto-correct-container {
    padding-left: $layout__width-xs-indent;
    padding-right: $layout__width-xs-indent;
}

@media (min-width: $bp-screen-m) {
    .page-products .page-title-wrapper > h1,
    .search-auto-correct-container {
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
    }
}


@media (min-width: $bp-columns) {

    .page-products .sidebar-main {
        width: 256px;
        margin-left: -20px;
        margin-top: -8px;
        margin-right: $space-l;
        background: $c-white;
        border-right: 1px solid $c-border-light;
    }

    .page-products .sidebar-main > div {
        margin-bottom: $space-xs;
        padding: $space-xs $space-base;
        border-bottom: 1px solid $c-border-light;
    }

}

