/**
* Baker Distributing
*/

// ==============================================
// Account Dropdown - Wrap
// ==============================================

.account-link {
    order: 10;
    flex-grow: 1;
    align-items: flex-end;
    color: $c-white;
}

.account-link a {
    color: $c-white;
    text-decoration: none;
}

.account-link > div {
    padding-top: $space-xxs;
}

@media (max-width: $bp-screen-m - 1px) {

    .account-link {
        display: none;
    }
}

.account-link p {
    color: $c-contrast;
    margin: 0;
    font-size: $font-size-xs;
    line-height: $font-size-xs;
}

// ----------------------------------------------

.account-dropdown-wrap > div.signed-in {
    padding-top: 3px;
}

// ==============================================
// Account Dropdown
// ==============================================

.authorization-link {;
    border-top: 1px solid $c-border-light;
}

.account-dropdown .authorization-link {;
    padding: $space-xs $space-base;
}

.account-dropdown .authorization-link a {
    color: $c-text;
}

.account-dropdown:before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -8px;
    right: 22px;
}

.account-dropdown:after {
    @include triangle($direction: up, $size: 6px, $color: $c-white);
    top: -6px;
    right: 24px;
}

@media (max-width: $bp-screen-m - 1px) {

    .account-dropdown {
        width: 100%;
    }
}

@media (min-width: $bp-screen-m) {
    .account-dropdown-erp {
        width: 402px;
    }

    .account-dropdown_links a {
        display: block;
        color: $c-text;
        padding: $space-xs $space-base;
    }

    .account-dropdown_links a:hover {
        background: $c-primary;
        color: $c-white;
        text-decoration: none;
    }

    .account-dropdown_company {
        border-left: 1px solid $c-border;
    }

    .account-dropdown_myaccount {
        width: 100%;
    }
}



// ==============================================
// Account Dropdown Links
// ==============================================


@media (min-width: $bp-screen-m) {
    .account-dropdown-erp .account-dropdown_links {
        width: 200px;
    }
}