/**
* Baker Distributing
*/

// ==============================================
// Tabs
// ==============================================

.tabs_nav {
    max-width: 100%;
    overflow-x: auto;
    display: flex;
}

.tabs_nav::-webkit-scrollbar {
    display: none;
}

.tabs_nav-link {
    line-height: 20px;
    padding: $space-s $space-base;
    white-space: nowrap;
    color: $c-tab-text;
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    cursor: pointer;
    border-bottom: 2px solid $c-tab-border-bottom;
    transition: color,border-color $transition-base;
}

.tabs_nav-link:hover {
    text-decoration: none;
    color: $c-tab-text-hover;
}

.tabs_nav-link.active {
    border-bottom: 2px solid $c-tab-border-bottom-active;
}

.tabs_nav-link.active {
    color: $c-tab-text-active;
}