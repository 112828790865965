.App {
  text-align: center;
}

.App-content {
  margin: 0 20px;
}

.RegistrationSteps .circle.blue {
  background: var(--ecm-blue);
  color: white;
}
