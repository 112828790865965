/**
* Baker Distributing
*/


// ==============================================
// Page
// ==============================================

@media (min-width: $bp-screen-m) {

    .store-locator-index-index .column.main {
        padding-bottom: 0;
    }

    .store-locator-index-index footer .footer {
        margin-top: 0;
    }

}
