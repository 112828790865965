/**
* Baker Distributing
*/


// ==============================================
// Animation - Show Message
// ==============================================

@keyframes showMessage {
    from {
        opacity: 0;
        filter: blur(2px);
    }
    to {
        filter: blur(0);
        opacity: 1;
    }
}
