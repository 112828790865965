/**
* Baker Distributing
*/


// ==============================================
// Account profile page
// ==============================================

$account-profile-grid-gap: 40px;
$account-profile-grid-col-2-width: 280px;

@media (min-width: $bp-screen-l) {
    .account-profile-grid > .col-1 {
        float: left;
        width: calc(100% - #{($account-profile-grid-gap + $account-profile-grid-col-2-width)});
        max-width: 900px;
    }

    .account-profile-grid > .col-2 {
        float: right;
        width: ($account-profile-grid-gap + $account-profile-grid-col-2-width);
        padding-left: $account-profile-grid-gap;
    }
}
