.CompleteForm-codeField.field {
    /* display: flex;
    justify-content: center;
    margin: 20px 0 10px 0; */
    width: 14rem;
    margin-left: auto;
    margin-right: auto;
}

.CompleteForm-codeInput, .CompleteForm-codeLabel {
    text-align: center;
}

.CompleteForm-codeLabel {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.CompleteForm-codeInput {
    letter-spacing: .5em;
    font-size: 20px;
}

.CompleteForm-actionGroup {
    justify-content: center;
}