/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// HR (Horizontal Rule)
// ==============================================

hr {
    margin-bottom: $gap;
    border: 0;
    border-top: 1px solid $c-hr;
}

// ----------------------------------------------
// Modifier: Dash

.hr--dash {
    border-style: dashed;
}

// ----------------------------------------------
// Modifier: Subtle

.hr--subtle {
    border-color: $c-hr-subtle;
}

// ----------------------------------------------
// Modifier: Thick

.hr--thick {
    border-top-width: 5px;
}
