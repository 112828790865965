/**
* Baker Distributing
*/


// ==============================================
// Price Container
// ==============================================

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee {
    margin-top: $space-xs;
}

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax + .price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee + .price-excluding-tax::before,
.price-container .weee + .price-excluding-tax .price {
    font-size: $font-size-xxs;
}

.price-container .weee {
    &::before {
        content: '('attr(data-label) ': ';
    }

    &::after {
        content: ')';
    }
}

.price-container .weee .price-excluding-tax {
    &::before {
        content: attr(data-label) ': ';
    }
}
