/**
* Baker Distributing
*/

// ==============================================
// User settings table
// ==============================================


.user-settings > tbody > tr.active {
    background: $c-background;
}

.user-settings > tbody > tr > th {
    width: 20%;
    color: $c-text;
}

.user-settings > tbody > tr > td.content {
    width: 65%;
}

.user-settings > tbody > tr > td.action {
    width: 15%;
}

.user-settings > tbody > tr > td .summary {
    color: $c-text-muted;
}

.user-settings > tbody > tr:hover > th,
.user-settings > tbody > tr:hover > td {
    background: $c-background;
}

.user-settings [type='checkbox'] + label {
    margin-left: -18px
}

.user-settings tr:hover table th,
.user-settings tr:hover table td {
    background: $c-white;
}

@media (max-width: $bp-table-linearize - 1px) {

    .user-settings > tbody > tr > th {
        padding: 0;
    }

    .user-settings > tbody > tr > th,
    .user-settings > tbody > tr > td.content,
    .user-settings > tbody > tr > td.action {
        width: 100%;
    }

    .user-settings > tbody > tr > td {
        padding: $space-base 0 0 0 !important;
    }

    .user-settings > tbody > tr > td,
    .user-settings > tbody > tr > th {
        border: none;
    }
}


// ==============================================
// User settings - Actions
// ==============================================

@media (max-width: $bp-table-linearize - 1px) {
    .user-settings .reset {
        display: block;
        width: 38px;
        white-space: nowrap;
        overflow: hidden;
    }
}


// ==============================================
// User settings - Approval section
// ==============================================


.order-approval_limit,
.order-approval_condition {
    margin-top: -8px;
}

.order-approval_limit {
    @include lib-icon-font(
                    $icon-dollar,
            $_icon-font-size: 16px,
            $_icon-font-color: $c-icon-base);
    &:before {
        position: absolute;
        margin-top: 8px;
    }
    input {
        padding-left: 18px;
    }
}

// ==============================================
// Order Approvals - list
// ==============================================

@media (min-width: $bp-table-linearize) {
    .order-approvals .col.items {
        text-align: right;
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .order-approvals tbody tr {
        cursor: pointer;
    }

    .order-approvals tbody tr td.selector {
        float: left;
        height: 120px;
        width: 36px;
    }

    .order-approvals tbody tr td.id,
    .order-approvals tbody tr td.items {
        float: left;
    }

    .order-approvals tbody tr td.id {
        margin-right: $space-s;
    }

    .order-approvals tbody tr td.status {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}



// ==============================================
// Order Approvals - view
// ==============================================

@media (min-width: $bp-table-linearize) {
    .approval-request_items .col.price,
    .order-approvals_children .col.items {
        text-align: right;
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .approval-request_items .col.image {
        float: left;
        margin-right: $space-s;
    }

    .order-approvals_children tbody tr td.items,
    .approval-request_items .col.price {
        float: left;
    }
}

