/**
* Baker Distributing
*/

// ==============================================
// Shipping Policy Block
// ==============================================

.shipping-policy-block.field-tooltip {
    top: 12px;
}

.shipping-policy-block.field-tooltip .field-tooltip-action {
    color: $c-link;
    cursor: pointer;

    &::before {
        display: none;
    }
}

.shipping-policy-block.field-tooltip .field-tooltip-action span {
    @include lib-visually-hidden-reset();
}

.shipping-policy-block.field-tooltip .field-tooltip-content {
    @extend .abs-checkout-tooltip-content-position-top;
    width: 420px;
    top: $space-xxl;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .shipping-policy-block.field-tooltip {
        margin-bottom: $space-l;
        position: relative;
        right: auto;
        top: auto;
    }

    .shipping-policy-block.field-tooltip .field-tooltip-content {
        width: 300px;
        right: auto;

        &::before,
        &::after {
            right: auto;
        }
    }

}
