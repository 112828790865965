.AccountNumber {
    width: 12.70em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.AccountNumber-input {
    font-size: 20px;
    letter-spacing: .5em;
    text-align: center;
}
.AccountNumber-input ~ label {
    left: 50%;
    transform: translateX(-50%);
}