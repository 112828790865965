/**
* Baker Distributing
*/


// ==============================================
// Product Image Container
// ==============================================

.product-image-container {
    display: inline-block;
    max-width: 100%;
}
