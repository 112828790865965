/**
* Baker Distributing
*/

// ==============================================
// Edit Account Form
// ==============================================

.form-edit-account .fieldset.password {
    display: none;
}
