:root {
    --header-background: var(--ecm-blue);
    --header-foreground: #fff;
}
.Header {
    background-color: var(--header-background);
    color: var(--header-foreground);
    padding: 36px 45px;
}

.Header-primary {
    display: flex;
    justify-content: space-between;
}

.Header-primary p {
    margin: 0 0 5px 0;
}

.Header-phone {
    display:flex;
}

.Header-phone div {
    text-align: start;
}

.Header-logo {
    margin: -5px 0 0 0;
    height: 52px;
}

.Header-searchForm {
    position: relative;
    flex: 1;
    margin: 0 20px;
}
.Header-search {
    height: 37px;
    padding: 8px 36px 8px 8px;
    width: 100%;
    border: 0;
    background: #fff;
    border-radius: 2px;
}
.Header-searchButton {
    background-color: transparent;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}
.Header-searchButton img {
    vertical-align: bottom;
}

.Header-storeLocator {
    font-size: 12px;
    padding-left: 38px;
    background: url(./location.svg) left center no-repeat;
    background-size: auto 37px;
    margin-left: -8px;
}
.Header-storeLocator span {
    display: block;
}
.Header-storeName {
    font-size: 14px;
}

.Header-nav .button {
    color: var(--header-foreground);
}
.Header-nav .button span {
    padding: 12px 20px;
}

.Header-nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.Header-nav > ul > li {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.Header-actions {
    display: flex;
    justify-content: space-between;
}
.Header-primaryNav {
    margin: 0 0 -12px -20px;
    font-weight: 400;
}
.Header-systemNav {
    margin: 0 -20px -12px 0;
}
.Header-cartLink img {
    margin: -2px 0;
}

@media only screen and (max-width: 768px) {

    .Header{
        padding: 25px 20px;
    }
    .Header-logo {
        margin: 5px 0 0 0;
        height: auto;
    }
    .Header-phone{
        justify-content: flex-end;
    }
    .Header-phone img {
        width: 15%;
        height: 70%;
        margin: 4px;
    }
    .Header-phone div{
        font-size: 12px;
    }

  }