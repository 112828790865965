.ContactMethodChooser {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
}
.ContactMethodList {
    text-align: left;
    width: fit-content;
}
.ContactMethod-radioButton {
    display: block;
}
.ContactList-contacts {
    display: flex;
    justify-content: center;
}
.ContactMethodChooser > .action-group {
    justify-content: center;
}