/**
* Baker Distributing
*/


// **********************************************
// [todo]
// This rainbow is incomplete.
// Consider dropping this and use the "color-library" which covers the full spectrum.
// **********************************************


// ==============================================
// Complete Color Palette
// ==============================================

$red-700            : #ea1336;
$red-800            : #c8102e;
$red-900            : #a60d26;

$blue-50            : #eef4fe;
$blue-100           : #ddeafc;
$blue-200           : #b6d4ff;
$blue-300           : #99c0f7;
$blue-400           : #6ca9ff;
$blue-500           : #5596f1;
$blue-600           : #3380ef;
$blue-700           : #136cea;
$blue-800           : #105cc8;
$blue-900           : #0d4ca6;

$gray-50            : #f4f4f4;
$gray-100           : #e9e9e9;
$gray-200           : #d3d3d3;
$gray-300           : #bebcbc;
$gray-400           : #a8a6a6;
$gray-500           : #929090;
$gray-600           : #7c7a7a;
$gray-700           : #666464;
$gray-800           : #504e4e;
$gray-900           : #3a3838;

$green-200          : #70D058;
$green-400          : #32bc61;
$green-500          : #0eb045;
$green-600          : #0ca93e;
$green-700          : #1F7E0C;
