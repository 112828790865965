/**
* Baker Distributing
*/


// ==============================================
// Scene 7 Viewers - Mixed Media
// ==============================================


.scene7viewer {
    position:relative;
    margin-top: $space-base;
}

.s7viewer.s7mixedmediaviewer {
    margin: 0 auto;
    height: 450px;
}

@media (min-width: 700px) {
    .s7viewer.s7mixedmediaviewer {
        width: 450px;
    }
}
