/**
* Baker Distributing
*/


// ==============================================
// Breadcrumbs
// ==============================================

.breadcrumbs {
    margin-bottom: 20px;
}

@media (min-width: $bp-screen-m) {
    //todo: remove this when we change the product breadcrumb to the Item #
    .breadcrumbs {
        padding-right: 430px !important;
    }
}

// ==============================================
// Breadcrumbs - Item
// ==============================================

.breadcrumbs_item {
    display: inline-block;
    font-size: $font-size-s;
    color: $c-text; // [todo] use var
}

.breadcrumbs_item:not(:last-child) {
    // After
    // [todo] use svg
    @include lib-icon-font(
        $_icon-font-content: $icon-pointer-right,
        $_icon-font: $font-family-icon,
        $_icon-font-size: 18px,
        $_icon-font-line-height: 18px,
        $_icon-font-color: $c-text-muted,
        $_icon-font-margin: 0,
        $_icon-font-vertical-align: top,
        $_icon-font-position: after
    );
}


// ==============================================
// Breadcrumbs - Link
// ==============================================

//.breadcrumbs_link {}
