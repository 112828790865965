/**
* Baker Distributing
*/

$products-list-gutter-s: $space-l;
$products-list-border-color: $c-border-light;

// ==============================================
// Products List > Product Item
// ==============================================

.products-list .product-item {
    width: 100%;
    padding: $space-base;
    margin-bottom: $products-list-gutter-s;
    border: 1px solid $products-list-border-color;
    background: $c-white;
}

.products-list .product-item-actions {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.products-list .product-item-info {
    display: flex;
    flex-wrap: nowrap;
}

.products-list .product-item-photo {
    flex: 0 1 240px;
    padding: 0 $space-l 0 0;
    vertical-align: top;
}

.products-list .product-item-details {
    flex: 1 1 auto;
    vertical-align: top;
}

.products-list .product-item-description {
    margin-top: $space-base;
    font-weight: $font-weight-light;
}

.products-list .product-item-description a.more {
    font-weight: $font-weight-normal;
}

.products-list .actions-primary {
    flex-basis: 250px;
    flex-grow: 0;
    flex-shrink: 1;
}

@media (min-width: $bp-screen-s) {
    .products-list .actions-primary {
        margin-right: 16px;
    }
}

.products-list .actions-secondary {
    flex-basis: 180px;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    margin-top: 0;
}

.products-list .block-requisition-list {
    order: 1;
    margin-right: 16px;
}

.products-list .tocompare {
    order: 2;
}

.products-list .product-image-wrapper {
    @extend .abs-reset-image-wrapper;
}

.products-list .product-item .tocompare {
    display: none;
}

.products-list .product-item .actions-secondary .tocompare {
    display: block;
    float: left;
    margin-top: 8px;
}


// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .products-list .block-requisition-list {
        margin-left: 0;
    }

}
