/**
* Baker Distributing
*/


// ==============================================
// Badge
// ==============================================

.badge {
    display: inline-block;
    padding: $space-xxxs 0.5em;
    border-radius: $radius-base;
    background: $c-darkgray;
    color: $c-white;
    font-size: $font-size-xs;
    font-weight: $font-weight-light;
    letter-spacing: $letter-spacing;
    text-shadow: 0 1px 1px black(0.4);
    text-transform: uppercase;
}

// ----------------------------------------------
// Modifier: Danger

.badge--danger {
    background: $c-danger;
}

// ----------------------------------------------
// Modifier: Info

.badge--info {
    background: $c-info;
}

// ----------------------------------------------
// Modifier: Success

.badge--success {
    background: $c-success;
}

// ----------------------------------------------
// Modifier: Warning

.badge--warning {
    background: $c-warning;
}


// ----------------------------------------------
// Print Pages

.page-print .badge {
    padding: 0;
    color: $c-text;
    text-shadow: none;
    font-weight: $font-weight-semibold;
    background: transparent;
}
