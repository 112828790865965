/**
* Baker Distributing
*/

// ==============================================
// Quote - line item comment
// ==============================================


.table tbody tr.quote-item_comment td {
    border-top: 0;
    padding-top: 0;
}

.table tbody tr.quote-item--has-comment td {
    border-bottom: 0;
    padding-bottom: $space-xxs;
}

@media (max-width: $bp-table-linearize - 1px) {
    .table--linearize tbody tr.quote-item_comment {
        border-top: 0;
        padding-top: 0;
    }
    .table--linearize tbody tr.quote-item--has-comment {
        padding-bottom: $space-xxs;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .quote-info {
        order: 1;
    }

    .quote-totals {
        order: 2;
    }
}


@media (max-width: $bp-screen-s - 1px) {
    .quote-header {
        flex-wrap: nowrap;
    }

    .quote-header_info {
        order: 1;
        width: auto;
    }

    .quote-header_action {
        order: 2;
        width: auto;
    }
}