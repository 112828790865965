/**
* Gravity Department - Notify
* https://github.com/gravitydepartment/notify
*
* @author     Brendan Falkowski
* @copyright  2017 Gravity Department. All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-notify-close-background-base:  $c-gray  !default;
$c-notify-close-background-hover: $c-darkgray !default;


// ==============================================
// Notify
// ==============================================

.notify {
  display: none;
  position: fixed; // Directions set by JS
  z-index: $z-notify;
  width: 100%;
  max-width: 400px;
}

[data-notify-state='active'] {
  display: block;
}


// ==============================================
// Notify - Item
// ==============================================

.notify_item {
  margin: 10px;
  padding: 20px;
  line-height: 1.3;
  transition: all 250ms ease-out;
}

.notify_item--right { transform: translate3d(450px, 0, 0); }
.notify_item--left  { transform: translate3d(-450px, 0, 0); }

[data-notify-item-state='slide-in'] {
  transform: translate3d(0, 0, 0);
}

[data-notify-item-state='fade-out'] {
  transform: translate3d(0, 0, 0); // Retain initial transformation when status is swapped
  opacity: 0;
}

// ----------------------------------------------
// Modifier: Persist

.notify_item--persist {
  position: relative;
  padding-right: (20px + 20px + 10px); // Padding + icon + padding
}


// ==============================================
// Notify - Item Close
// ==============================================

.notify_item-close {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: $c-notify-close-background-base;
  color: #FFF;
  font-family: arial;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  transform: translateY(-50%);

  &:hover,
  &:active {
    background: $c-notify-close-background-hover;
    color: #FFF;
  }
}