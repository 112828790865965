/**
* Baker Distributing
*/


// ==============================================
// Var
// ==============================================

$minicart-padding: $space-base;

$minicart-quantity-height : 24px;


// ==============================================
// Minicart
// ==============================================

.block-minicart a:not(.button) {
    color: $c-link;

    &:hover {
        color: darken($c-primary, 10%);
    }
}

.block-minicart .items-total {
    float: left;
}

.block-minicart .items-total .count {
    font-weight: $font-weight-bold;
}

.block-minicart .subtotal {
    margin-bottom: $space-base;
    text-align: right;
}

.block-minicart .amount {
    display: inline-block;
}

.block-minicart .amount .price-wrapper:first-child .price {
    font-weight: $font-weight-bold;
}

.block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: $space-xxl 0 $space-l;
    text-align: center;
}

.block-minicart .text.empty {
    text-align: center;
}

.minicart-actions > * {
    margin-bottom: $space-xs;
}

.minicart-actions_extra:after {
    @include clearfix;
}

@media (min-width: $bp-screen-m) {
    .minicart-actions_viewcart {
        width: 46%;
    }

    .minicart-actions_checkout,
    .minicart-actions_extra {
        width: 50%;
        float: right;
    }
}


@media (max-width: $bp-screen-m - 1px) {
    .minicart-actions_extra {
        width: 100%;
    }
    .minicart-actions_extra > * {
        margin-bottom: $space-xxs;
    }
}



.block-minicart .block-content > .actions > .primary {
    margin: 0 $space-base $space-base;
}

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center;
}


// ==============================================
// Minicart Wrapper
// ==============================================

.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector                     : '.action.showcart',
        $_options-selector                    : '.block-minicart',
        $_dropdown-toggle-icon-content        : $icon-cart,
        $_dropdown-toggle-active-icon-content : $icon-cart,
        $_dropdown-list-item-padding          : false,
        $_dropdown-list-item-hover            : false,
        $_dropdown-list-pointer               : false,
        $_icon-font-position                  : before,
        $_icon-font-size                      : 32px,
        $_icon-font-line-height               : 28px,
        $_icon-font-color                     : $c-white,
        $_icon-font-color-hover               : $c-white,
        $_icon-font-color-active              : $c-white
    );
}

.minicart-wrapper .block-minicart {
    padding: $minicart-padding;
    right: 0;
    width: 320px;
    z-index: 101;
}

.minicart-wrapper .block-minicart .block-title {
    display: none;
}

.minicart-wrapper .action.showcart {
    white-space: nowrap;
}

.minicart-wrapper .action.showcart .text {
    @include lib-visually-hidden;
}

.minicart-wrapper .action.showcart .counter.qty {
    background: $c-secondary;
    color: $c-white;
    height: $minicart-quantity-height;
    line-height: 23px;
    font-size: $font-size-xs;
    border-radius: 50%;
    display: inline-block;
    margin: 11px 0 0 0;
    min-width: $minicart-quantity-height;
    overflow: hidden;
    padding: 0 3px;
    text-align: center;
    white-space: normal;
}

.minicart-wrapper .action.showcart .counter.qty.empty {
    display: none;
}

.minicart-wrapper .action.showcart .counter.qty .loader > img {
    max-width: $minicart-quantity-height;
}

.minicart-wrapper .action.showcart .counter-label {
    @include lib-visually-hidden;
}

.minicart-wrapper .minicart-widgets {
    margin-top: $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-xs - 1px) {

    .minicart-wrapper .block-minicart {
        width: 290px;
    }

}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .minicart-wrapper {
        margin-top: 11px;
    }

    .minicart-wrapper .action.showcart .counter.qty {
        margin: 3px 0 0 0;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .minicart-wrapper {
        margin-left: 25px;
        order: 30;
    }

    .minicart-wrapper .block-minicart {
        width: 390px;
    }

    .minicart-wrapper .action.showcart,
    .minicart-wrapper .action.showcart.active {
        line-height: 1;
        display: flex;

        &::before {
            margin-top: 7px;
        }
    }

}


// ==============================================
// Minicart Items
// ==============================================

.minicart-items-wrapper {
    overflow-x: auto;
    border-left: 0;
    border-right: 0;
    width: 100%;
}


.minicart-item {
    border-top: 1px solid $c-border;
    padding: $space-l 0;
}

.minicart-item-wrapper {
    @include lib-clearfix;
    position: relative;
}

.minicart-item_price {
    float: left;
}

.minicart-item_action {
    float: right;
    margin-left: $space-base;
}

.minicart-item_qty {
    position: relative;
    padding-right: $space-xxl;
}

@media (max-width: $bp-screen-m - 1px) {

    .minicart-item_action {
        display: none;
    }
}

.minicart-item-wrapper > .minicart-item_photo {
    position: absolute;
    left: 0;
    width: auto;
}

.minicart-item .minicart-item_name a {
    color: $c-link;

    &:hover {
        color: $c-link-hover;
    }
}

.minicart-item .minicart-item_details {
    padding-left: 88px;
}

.minicart-item .minicart-item_details .price {
    font-weight: $font-weight-bold;
}

.minicart-item .minicart-item_details .price-including-tax,
.minicart-item .minicart-item_details .price-excluding-tax {
    margin: $space-xs 0 0;
}

.minicart-item .minicart-item_details .weee[data-label] {
    font-size: $font-size-xxs;
}

.minicart-item .minicart-item_details .weee[data-label] .label {
    display: none;
}

.minicart-item .input-text.qty {
    text-align: center;
    height: 25px;
    width: 40px;
}

.minicart-item .update-cart-item {
    background: transparent;
    border: none;
    @include lib-icon-font(
                    $icon-update,
            $_icon-font-size         : 28px,
            $_icon-font-line-height  : 28px,
            $_icon-font-text-hide    : true,
            $_icon-font-color        : $gray-700,
            $_icon-font-color-hover  : $gray-700,
            $_icon-font-color-active : $gray-700
    );

    position: absolute;
    right: 0;
    top: 0;
}

.minicart-item .action.delete {
    @include lib-icon-font(
                    $icon-trash,
            $_icon-font-size         : 28px,
            $_icon-font-line-height  : 28px,
            $_icon-font-text-hide    : true,
            $_icon-font-color        : $gray-700,
            $_icon-font-color-hover  : $gray-700,
            $_icon-font-color-active : $gray-700
    );
}

.minicart-item .action.delete[disabled="disabled"],
.minicart-item .update-cart-item[disabled="disabled"] {
    &:before {
        opacity: 0.5;
    }
}