/**
* Baker Distributing
*/

$checkbox-size: 22px;

@font-face {
    font-family: "MaterialIcons";
    src: url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }

// ==============================================
// Checkbox
// ==============================================

[type='checkbox'] {
    margin-left: $space-xxs;
}


// ==============================================
// Checkbox + Label
// ==============================================

[type='checkbox'] + label {
    position: relative;
    margin-left: -$checkbox-size;
    padding-left: 28px;
    cursor: pointer;

    &::before,
    &::after {
        position: absolute;
        transition:
            content .3s ease,
            background .3s ease,
            box-shadow .3s ease,
            transform .3s ease;
    }

    &::before {
        content: '';
        width: 45px;
        height: 45px;
        top: 49%;
        left: 9px;
        border-radius: 50%;
        background: $c-lightestblue;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }

    &::after {
        font-family: "MaterialIcons";
        content: '\E835';
        display: inline-block;
        top: -2px;
        left: 0;
        width: $checkbox-size;
        height: $checkbox-size;
        border-radius: $radius-base;
        background: $c-white;
        box-shadow: none;
        -moz-osx-font-smoothing: grayscale;
        font-size: $checkbox-size;
        background: $c-white;
        color: $gray-500;
        line-height: 18px;
        vertical-align: top;
    }
}

// ----------------------------------------------
// State: Checked

[type='checkbox']:checked + label,
[type='checkbox']:indeterminate + label {
    &::before {
        animation: choice-ripple 1s none;
        transform: translate(-50%, -50%) scale(1);
    }

    &::after {
        font-family: "MaterialIcons";
        content: '\E834';
        color: $blue-600;
    }
}

[type='checkbox']:indeterminate + label {
    &::after {
        content: $icon-indeterminate;
        color: $blue-600;
    }
}

// ----------------------------------------------
// State: Disabled

[type='checkbox']:disabled + label::after {
    color: $gray-200;
}
