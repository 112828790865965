/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Units
// ==============================================

// Hex is the percentage difference between 101010 and 202020.
// Used with Sass functions darken() and lighten().
$hex: 6.1%;


// ==============================================
// Brand Colors
// ==============================================

$c-brand: $red-800;


// ==============================================
// Named Colors
// ==============================================

// Named colors are rarely used in other partials. They establish the standard
// palette to be referenced from other variables in this file.

// Primary + secondary colors
$c-blue:         $c-library-blue-8;
$c-blue-dark:    $c-library-blue-8;
$c-green:        $c-library-green-6;
$c-orange:       $c-library-orange-6;
$c-purple:       $c-library-violet-3;
$c-red:          $c-library-red-6;
$c-yellow:       $c-library-yellow-5;
$c-yellow-light: $c-library-yellow-0;

$c-white: #FFF;
$c-black: #000;

// Baker [todo]
// Consider normalizing color libraries
$c-red:    $red-700;
$c-yellow: #ffee9c;

$c-darkgray:     $gray-700;
$c-gray:         $gray-400;
$c-lightgray:    $gray-200;
$c-lightestgray: $gray-50;

$c-lightestblue: $blue-50;
$c-lightblue:    $blue-300;


$c-action-secondary-base:   $c-gray              !default;
$c-action-secondary-hover:  darken($c-gray, 5%)  !default;
$c-action-secondary-focus:  darken($c-gray, 5%)  !default;
$c-action-secondary-active: darken($c-gray, 10%) !default;


// Natural colors
$c-concrete: hsl(220, 13%, 67%); // Light
$c-slate:    hsl(220, 13%, 45%); // Dark


// ==============================================
// UI State Colors
// ==============================================

$c-action:   $c-blue;
$c-stimulus: $c-blue-dark;
$c-subtle:   $c-concrete;

// Baker [todo]
$c-primary:   $blue-700;
$c-secondary: $gray-900;

// ----------------------------------------------
// Notifications

$c-danger:   $c-red;
$c-help:     $c-purple;
$c-info:     $c-blue;
$c-security: #E0F2FF;
$c-success:  $c-green;
$c-warn:     $c-yellow;

// Baker [todo]
$c-error:   #e04245;
$c-warning: #EFA133;
$c-success: $green-700;

// ----------------------------------------------
// Tone - Default

$c-background: $gray-50;
$c-border: $gray-200;
$c-border-light: $gray-100;
$c-overlay: black(0.25);


// ----------------------------------------------
// Tone - Contrast

$c-contrast:            $c-white;
$c-contrast-background: $gray-900; // Tint blue +4
$c-contrast-border:     darken($c-contrast-background, 10%);

// ----------------------------------------------
// Tone - Emphasis

$c-emphasis-background: #E8E8E8;
$c-emphasis-border:     #C0C0C0;

// ----------------------------------------------
// Tone - Help

$c-help-background:        $c-purple;
$c-help-background-hover:  darken($c-purple, 4%);
$c-help-background-focus:  darken($c-purple, 4%);
$c-help-background-active: darken($c-purple, 4%);

// ----------------------------------------------
// Tone - Offset

$c-offset-background: #808084; // Tint blue +4
$c-offset-border:     darken($c-offset-background, 10%);

// ----------------------------------------------
// Tone - Priority

$c-priority-background: $c-yellow-light;

// ----------------------------------------------
// Tone - Subtle

$c-subtle-background: #FFF;
$c-subtle-border:     #E0E0E0;


// ==============================================
// Text Colors
// ==============================================

$c-text: black(0.87);

$c-text-contrast:        #FFF;
$c-text-contrast-subtle: #C0C0C0;

$c-text-muted:       black(0.54);
$c-text-muted-light: black(0.42);
$c-text-muted-dark:  black(0.66);

$c-text-success: $c-success;
$c-text-danger: $c-danger;
$c-text-warning: #A06B22;


// ==============================================
// Element Colors
// ==============================================

// ----------------------------------------------
// Badge

$c-badge-new:  $c-green;
$c-badge-sale: $c-blue;

$c-badge-cancelled:  $c-red;
$c-badge-closed:     $c-red;
$c-badge-complete:   $c-green;
$c-badge-pending:    $c-blue;
$c-badge-processing: $c-blue;

// ----------------------------------------------
// Button

$c-button:        $c-slate;
$c-button-hover:  darken($c-slate, 5%);
$c-button-focus:  darken($c-slate, 5%);
$c-button-active: darken($c-slate, 5%);

$c-button-cta:        $c-action;
$c-button-cta-hover:  darken($c-action, 5%);
$c-button-cta-focus:  $c-blue-dark;
$c-button-cta-active: darken($c-action, 5%);

$c-button-danger:        $c-danger;
$c-button-danger-hover:  darken($c-danger, 5%);
$c-button-danger-focus:  darken($c-danger, 5%);
$c-button-danger-active: darken($c-danger, 5%);

$c-button-disabled:      $c-background;
$c-button-disabled-text: #A4A4A8; // Tint blue +4

$c-button-subtle:        $c-subtle;
$c-button-subtle-hover:  darken($c-button-subtle, 5%);
$c-button-subtle-focus:  darken($c-button-subtle, 5%);
$c-button-subtle-active: darken($c-button-subtle, 5%);

// ----------------------------------------------
// Contrast

$c-contrast-flat-button: $c-lightblue;

$c-contrast-input-border-bottom: $c-white;
$c-contrast-input-border-focus:  $c-lightblue;
$c-contrast-input-text:          $c-white;
$c-contrast-input-text-fill:     $c-lightgray;

$c-contrast-label:       $c-lightgray;
$c-contrast-label-focus: $c-lightblue;

// ----------------------------------------------
// Footer

$c-footer-bar-background: #000;

// ----------------------------------------------
// Form

$c-input-border:        $c-border;
$c-input-border-bottom: $gray-600;
$c-input-border-focus:  $blue-700;
$c-input-focus-error:   $c-red;
$c-input-placeholder:   $gray-500;
$c-input-text:          $c-text;
$c-input-text-fill:     $gray-800;

$c-label:               $c-text-muted;
$c-label-focus:         $c-primary;

// ----------------------------------------------
// Tabs

$c-tab-border-bottom:           transparent;
$c-tab-border-bottom-active:    $c-primary;
$c-tab-text:                    $c-text;
$c-tab-text-hover:              $blue-700;
$c-tab-text-active:             $blue-800;

// ----------------------------------------------
// Heading

$c-h1: $c-text;
$c-h2: $c-text;
$c-h3: $c-text;
$c-h4: $c-text;
$c-h5: $c-text;
$c-h6: $c-text;

// ----------------------------------------------
// HR

$c-hr:        #D0D0D0;
$c-hr-subtle: #E0E0E0;

// ----------------------------------------------
// Link

$c-link:        $c-action;
$c-link-hover:  $c-stimulus;
$c-link-focus:  $c-stimulus;
$c-link-active: $c-stimulus;

// Baker [todo]
$c-link:         $c-primary;
$c-link-hover:   $blue-600;
$c-link-visited: $c-link;
$c-link-active:  $c-link;
$c-link-focus:   $c-link;

$c-link-dark:    $blue-800;
$c-link-dark-hover:   $blue-700;
$c-link-dark-visited: $c-link-dark;
$c-link-dark-active:  $c-link-dark;
$c-link-dark-focus:   $c-link-dark;

// ----------------------------------------------
// Note

$c-note-danger-background: $c-library-red-1;
$c-note-danger-border:     $c-library-red-3;

$c-note-warn-background: $c-library-yellow-0;
$c-note-warn-border:     $c-library-yellow-2;

// ----------------------------------------------
// Social Networks

$c-facebook:    #3B5998;
$c-google-plus: #E04B31;
$c-linkedin:    #0075B6;
$c-pinterest:   #DE2108;
$c-tumblr:      #2C4762;
$c-twitter:     #00ACED;
$c-vimeo:       #1AB7EA;
$c-vk:          #567DA4;
$c-youtube:     #E52D27;
$c-weibo:       #EBDBC4;


// ==============================================
// Icon
// ==============================================

$c-icon-base:   black(0.54);
$c-icon-active: $c-black;


// ==============================================
// Inventory
// ==============================================

$c-inventory-available:   $c-success;
$c-inventory-unavailable: $c-darkgray;


// ==============================================
// Header
// ==============================================

$c-header-background: $red-800;
