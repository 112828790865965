/**
* Baker Distributing
*/


// ==============================================
// Parts List Page
// ==============================================

@media (min-width: $bp-screen-m) {
    .parts {
        display: flex;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .parts {
        max-width: none;
    }
}


// ==============================================
// Parts - Categories menu
// ==============================================

.parts-categories {
    background: $c-white;
    border: 1px solid $c-border-light;
    margin-left: -$layout-indent__width;
    margin-right: $layout-indent__width;
    margin-top: -$space-xxl;
    margin-bottom: -53px;
}

@media (max-width: $bp-screen-m - 1px) {
    .parts-categories {
        margin: 0px $layout__width-xs-indent 0px -$layout__width-xs-indent;
    }
}

.parts-categories li {
    white-space: nowrap;
    padding: $space-base;
    cursor: pointer;
    transition: $transition-all;
    width: 250px;
}

.parts-categories li:hover {
    background: $gray-100;
}

.parts-categories li.parts-cat--active {
    background: $c-secondary;
    color: $c-white;
}


// ==============================================
// Parts Grid
// ==============================================

.parts-grid {
    display: none;
}

.parts-grid--active {
    display: flex;
}


// ==============================================
// Replacement Parts
// ==============================================

.parts-replacement_heading {
    background: $c-lightestgray;
}

.replacement-product {
    display: flex;
    border: 1px solid $c-border-light;
    padding: $space-s;
    margin-bottom: $space-s;
}

@media (max-width: $bp-screen-m - 1px) {
    .replacement-product {
        display: block;
    }
}

.parts-replacement_heading .replacement-product {
    border: none;
    display: flex;
    margin-bottom: $space-l;
}

.parts-replacement_heading .replacement-product_info {
    align-self: center;
}

.parts-replacement_heading .replacement-product_image img {
    height: auto;
}

.replacement-product_image {
    margin-right: $space-base;
}

@media (max-width: $bp-screen-m - 1px) {
    .replacement-product_image img {
       margin: 0 auto;
    }
}

.replacement-product_name {
    color: $c-text;
}

.replacement-product_actions {
    width: 186px;
}
@media (min-width: $bp-screen-m) {
    .replacement-product_actions {
        margin-left: auto;
    }

    .replacement-product_actions .product-inventory {
        margin: 0;
    }
}