/**
* Baker Distributing
*/

// ==============================================
// Content Wrapper
// ==============================================

.content-wrapper {
    background: $c-background;
    padding: $space-xxl $layout-indent__width;
    height: 100%;
}

@media (max-width: $bp-screen-m - 1px) {
    .content-wrapper {
        padding: $space-xxl $layout__width-xs-indent;
    }
}

// ==============================================
// Content Wrapper on Account section
// ==============================================


.account .content-wrapper {
    margin-right: -$layout-indent__width;
    margin-left: -$layout-indent__width;
}

@media (max-width: $bp-screen-m - 1px) {
    .account .content-wrapper {
        margin-right: -$layout__width-xs-indent;
        margin-left: -$layout__width-xs-indent;
    }
}
